<script>
/** Anzeige der Hinweise zum Datenschutz im Kasten unten auf verschiedenen Seiten
 * @displayName Hinweise zum Datenschutz
 */
 export default {
 }
</script>
<template>
  <div
    class="datenschutzhinweis text-xs mt-5 p-5 border border-secondary/50/50 rounded-lg"
  >
    <p class="mb-5">
      Haben Sie eine betriebliche Altersversorgung, die von der Pensus
      Pensionsmanagement GmbH verwaltet wird, benötigen wir Ihre Daten. Wir
      verwenden die Daten, die uns von Ihrem Arbeitgeber zur Verfügung gestellt
      wurden, zur Durchführung von Verwaltungsaufgaben der betrieblichen
      Altersversorgung.
    </p>
    <p>
      Empfänger Ihrer Daten können im Rahmen gesetzlicher Pflichten und
      Mitteilungsbefugnisse Dritte oder von uns beauftragten Dienstleister sein.
      Allgemeine Informationen zur Datenverarbeitung und zu Ihren Rechten finden
      Sie unter
      <a
        href="https://www.pensus.de/datenschutz"
        target="_blank"
        class="text-primary underline"
      >
        https://www.pensus.de/datenschutz
      </a>
      . Bei Fragen wenden Sie sich an die Pensus Pensionsmanagement GmbH -
      Gothaer Platz 10 - 37083 Göttingen, oder unseren Datenschutzbeauftragten
      unter
      <a href="mailto:datenschutz@pensus.de" class="text-primary underline">
        datenschutz@pensus.de
      </a>
      .
    </p>
  </div>
</template>
