// Inbox.vue
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <div v-if="this.msg" class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto mb-8">
      <h1
        class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
        {{ this.msg.MSGBETREFF }}
      </h1>

      <div class="max-w-5xl mx-auto px-5 lg:px-0">
        <div class="content max-w-3xl mx-auto [&>p]:pb-8 mb-8">
          <div class="font-bold text-sm mb-4">
            <input
              v-if="msg.MSGDATE && msg.MSGDATE > 0"
              type="date"
              v-model="msg.MSGDATE"
              disabled />
          </div>
          {{ this.msg.MSGNACHRICHT }}
        </div>
        <div v-if="msg.MSGDOKID && msg.MSGDOKID.length > 0" class="attachment">
          <button @click="getFile(msg.MSGDOKID)">
            <svg
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              class="w-8 h-8">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>
            </svg>
          </button>
        </div>
        <ButtonBack />
      </div>
    </div>
    <div v-else class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
      <p>Beim Einlesen der Nachricht trat ein Fehler auf.</p>
    </div>

    <FooterMenu />
  </div>
</template>

<script>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import ButtonBack from "../components/ButtonBack.vue";
import { store } from "../components/store.vue";
/** View-Komponente zur Anzeige des Inhaltes einer Postfach-Nachricht
 * @displayName Seite Postfach-Detailanzeige
 */
export default {
  name: "Postfach Nachricht",

  components: {
    HauptMenue,
    FooterMenu,
    ButtonBack,
    store,
  },
  data() {
    return {
      messages: store.data.Message.MSGIDENT,
      msgIdent: store.currMsgIdent, // Übergabeparameter aus der Postfach-Übersicht
      msg: false, // Gefüllt in mounted()
      store,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    const index = this.messages.findIndex(
      (message) =>
        message &&
        message["@attributes"] &&
        message["@attributes"].MSGIDENT === this.msgIdent
    );
    if (index !== -1 && this.messages[index]) {
      this.msg = this.messages[index];
    }
    store.currMsgIdent = false;
  },
  computed: {},
  methods: {},
};
</script>
