import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import VueCryptojs from 'vue-cryptojs'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router/index.js'
import { createRouter, createWebHistory } from 'vue-router'
import servertalk from './plugins/serverTalk.vue'
import VueMatomo from 'vue-matomo'


let app = createApp(App);



//createApp(App).use(VueCryptojs).use(VueAxios, axios).mount('#app');
app.use(VueCryptojs,VueAxios, axios, createRouter, createWebHistory)
.use(servertalk)
.use(router)
.use(VueMatomo, {
    // Konfigurieren Sie hier Ihren Matomo-Server und Ihre Website
    host: 'https://portal.pensus.de/matomo/',
    siteId: 1,
    router: router,
    enableLinkTracking: true,
    preInitActions: [],
    trackSiteSearch: false,
    crossOrigin: undefined,

})
.mount('#app');

window._paq.push(['setDocumentTitle', "Enter System"]);
window._paq.push(['trackPageView']);

//module.exports
export default  {
    //...
    
 };

