<template>
  <div class="flex h-2 border border-tertiary/10 bg-tertiary/5 rounded" :class="[text]">
    <div :style="{ width: `${width}%` }" :class="[color]" class="progress-bar flex h-full" />
  </div>
</template>

<script>
import { computed } from "vue";

/** Fortschrittsanzeige für die Passworteingabe
 *   @displayName Progressbar
 */

export default {
  props: {
    /**
     * Anzahl der Abstufungen für Meldungen zur Passwort-Sicherheit
     */
    max: {
      type: Number,
      required: true
    },
    /**
     * Aktuelle Stufe der Passwort-Sicherheit
     */
    value: {
      type: Number,
      required: true
    },
    /**
     * Farbe, in der der Hintergrund zur aktuellen Stufe angezeigt werden soll (als Tailwind-Klasse z.B. "bg-red-600")
     */
    color: {
      type: String,
      default: "bg-transparent"
    },
    /**
     * Textfarbe der aktuellen Stufe (als Tailwind-Klasse z.B. '&+p:text-red-600' )
     */
    text: {
      type: String,
      default: ""
    },
  },

  setup(props) {
    const width = computed(() => (props.value / props.max) * 100);

    return { width };
  },
};
</script>

<style scoped>
.progress-bar {
  transition: 0.5s ease-in-out;
}
</style>
