<script setup>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import DokumenteHochladen from "../../components/DokumenteHochladenService.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
import { getNamePattern } from "../../utility";
import { isSteuerIdValid } from "validate-steuerid";
import { onMounted } from "vue";
import { Tooltip } from 'flowbite';

/** View-Komponente zum Erfassen von Änderungsaufträgen zu Name und Persoenlichedaten
 * @displayName Selfservice Änderung Name und Persoenlichedaten
 */
onMounted(() => {
  // set the tooltip content element
  const $targetElnamensvorsatz = document.getElementById('tooltipContentnamensvorsatz');
  const $targetElnamenszusatz = document.getElementById('tooltipContentnamenszusatz');
  const $targetElsozialversicherungsnummer = document.getElementById('tooltipContentsozialversicherungsnummer');
  const $targetEltitel = document.getElementById('tooltipContenttitel');
  // set the element that trigger the tooltip using hover or click
  const $triggerElnamensvorsatz = document.getElementById('tooltipButtonnamensvorsatz');
  const $triggerElsozialversicherungsnummer = document.getElementById('tooltipButtonsozialversicherungsnummer');
  const $triggerElnamenszusatz = document.getElementById('tooltipButtonnamenszusatz');
  const $triggerEltitel = document.getElementById('tooltipButtontitel');
  // options with default values
  const options = {
    placement: 'top',
    triggerType: 'hover',

  };

  const tooltipnamensvorsatz = new Tooltip($targetElnamensvorsatz, $triggerElnamensvorsatz, options);
  const tooltipnamenszusatz = new Tooltip($targetElnamenszusatz, $triggerElnamenszusatz, options);
  if (null !== $targetElsozialversicherungsnummer) {
    const tooltipsozialversicherungsnummer = new Tooltip($targetElsozialversicherungsnummer, $triggerElsozialversicherungsnummer, options);
    tooltipsozialversicherungsnummer.hide();
  }
  const tooltiptitel = new Tooltip($targetEltitel, $triggerEltitel, options);
  // instance options with default values

  tooltipnamensvorsatz.hide();
  tooltipnamenszusatz.hide();
});
</script>
<script>

export default {
  name: "SelfServiceAenderungPersoenlichedaten",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    CustomSelect,
    Alertbox,
    ButtonBack,
  },
  data() {
    return {
      store,
      sFamStandOptions: this.$servertalk.getSelectOpts("FAMSTAND"),
      sFamStand: {
        text: this.$servertalk.getName(
          "FAMSTAND",
          store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD
        ),
        key: store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD,
      },
      saved: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);


  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.olddraft.sService.Entwuerfe.AenderungPersoenlichedaten;
      delete store.draft.sService.Entwuerfe.AenderungPersoenlichedaten;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("Inhalt store:");
    this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "AenderungPersoenlichedaten",
      '{"NAME":"","VORNAME":"","TITEL":"","NAMEVS":"","NAMEZUS":"","FAMSTD":"","uuid":"","STEUERIDNR":"","SVNR":"","uploadsDokumente":[],"version":"1.0"}',
      "1.0"
    );
    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft = store.olddraft.sService.Entwuerfe.AenderungPersoenlichedaten;
      olddraft.NAME = store.data.Stammdaten.NAME;
      olddraft.VORNAME = store.data.Stammdaten.VORNAME;
      olddraft.TITEL = store.data.Stammdaten.TITEL;
      olddraft.NAMEVS = store.data.Stammdaten.NAMEVS;
      olddraft.NAMEZUS = store.data.Stammdaten.NAMEZUS;
      olddraft.FAMSTD = store.data.Stammdaten.FAMSTD; // Dropdown
      olddraft.uuid = this.$servertalk.getUUID();
      olddraft.SVNR = store.data.Stammdaten.SVNR;
      olddraft.STEUERIDNR = store.data.Stammdaten.STEUERIDNR;
      //store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.STEUERIDNR
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService("AenderungPersoenlichedaten");
    }
    //store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD = store.data.Stammdaten.FAMSTD;

    //if (typeof store.draft.sService.Firmenaustritt.gueltigAb ==='undefined'){store.draft.sService.Firmenaustritt.gueltigAb=0}
  },
  methods: {
    infoDocStatus(val) {
      this.$servertalk.doLog("infoDocStatus: ");
      this.$servertalk.doLog(val);
    },
    infoDocOk(val) {
      this.$servertalk.doLog("infoDocOk: " + val.uploadStatus);
    },
    docDocStatus(val) {
      this.$servertalk.doLog("docDocStatus: " + val.uploadStatus);
    },
    docDocOk(val) {
      this.$servertalk.doLog("docDocOk: " + val.uploadStatus);
    },
    newfiles(val, i) {
      this.$servertalk.doLog("newFile");
      this.$servertalk.doLog(val);
      store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.uploadsDokumente = val;
    },
    validVORNAME() {
      const pattern = getNamePattern();
      return store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.VORNAME.match(
        pattern
      );
    },
    validNAME() {
      const pattern = getNamePattern();
      return store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.NAME.match(
        pattern
      );
    },
    setFamStand(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AenderungPersoenlichedaten"
      );
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Änderungen im Bereich\n Änderung Name/Persoenlichedaten \n wurden erfolgreich beauftragt.",
            status: "ok",
          }
          : {
            text:
              "Beim Speichern des Auftrags im Bereich\n Änderung Name/Persoenlichedaten \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      if (messageCode == 0) this.saved = true;
      this.$router.push("/auftrag-an-pensus");
    },
    resetData() {
      //this.$refs.SelectFamStand.reset(); // Zurücksetzen Select
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AenderungPersoenlichedaten"
      );
      /*
      this.sFamStand = {
        text: this.$servertalk.getName(
          "FAMSTAND",
          store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD
        ),
        key: store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD
      };*/
      this.$servertalk.doLog(
        store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD
      );
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Änderungen im Bereich\n  Änderung Name/Persoenlichedaten \n wurden zurückgesetzt.",
            status: "ok",
          }
          : {
            text:
              "Beim Zurücksetzen der Daten im Bereich\n Änderung Name/Persoenlichedaten \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
    },
  },
  computed: {
    validSteuerid() {
      return isSteuerIdValid(store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.STEUERIDNR);
    },
    showFamStand() {
      let r = false;
      //console.log(store.data.Versorgung.PMNR);
      if (Array.isArray(store.data.Versorgung.PMNR)) {
        //es ist ein Array. Alles ok.
        let vari = store.data.Versorgung.PMNR;
        let ausk = vari.find((PMNR) => PMNR["AUSZAHLKNR"] == "4");
        r = ausk && ausk.length > 0;
      } else if (typeof store.data.Versorgung.PMNR == "object") {
        r = store.data.Versorgung.PMNR["AUSZAHLKNR"] == "4";
      }
      return r;
    },

    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      const aenderung = this.$servertalk.gettAttr("data.Stammdaten");
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("draft.sService.Entwuerfe.AenderungKontaktdaten");

      return aenderung;
    },
    setStatusClass() {
      // Erhalte den Wert "AenderungPersoenlichedaten" von $servertalk
      const aenderungPersoenlichedatenValue = this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungPersoenlichedaten"
      );
      return aenderungPersoenlichedatenValue;
    },
    setStatusClassTITEL() {
      // Erhalte den Wert "TITEL" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungPersoenlichedaten.TITEL"
      );
    },
    setStatusClassNAMEVS() {
      // Erhalte den Wert "NAMEVS" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungPersoenlichedaten.NAMEVS"
      );
    },
    setStatusClassVORNAME() {
      // Erhalte den Wert "VORNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungPersoenlichedaten.VORNAME"
      );
    },
    setStatusClassNACHNAME() {
      // Erhalte den Wert "NACHNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungPersoenlichedaten.NAME"
      );
    },
    setStatusClassNAMEZUS() {
      // Erhalte den Wert "NAMEZUS" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungPersoenlichedaten.NAMEZUS"
      );
    },
    setStatusClassFAMSTD() {
      // Erhalte den Wert "FAMSTD" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1 class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Ich möchte die Änderung meiner persönlichen Daten melden
          </h1>
          <!--modal content-->
          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <form :class="setStatusClass">
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendTitel" class="text-sm mb-2 block relative">Titel

                  <span class="absolute top-0.5 right-0 cursor-pointer hover:text-primary transition-all duration-300"
                    id="tooltipButtontitel"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                      fill="currentColor" class="w-4 h-4">
                      <path fill-rule="evenodd"
                        d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-6 3.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.293 5.293a1 1 0 1 1 .99 1.667c-.459.134-1.033.566-1.033 1.29v.25a.75.75 0 1 0 1.5 0v-.115a2.5 2.5 0 1 0-2.518-4.153.75.75 0 1 0 1.061 1.06Z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                  <div id="tooltipContenttitel" role="tooltip" data-tooltip-placement="top"
                    class="tooltip invisible absolute z-10 inline-block rounded-md text-gray-900 bg-gray-100 px-3 py-2 text-sm font-medium opacity-0 shadow-sm transition-all duration-300">
                    z. B. Dr., Prof. Dr., ...
                    <div class="tooltip-arrow" data-popper-arrow id="arrow"></div>
                  </div>


                </label>
                <input maxlength="100" type="text" id="aendTitel" :class="setStatusClassTITEL"
                  class="w-full p-3 border rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 border-secondary/50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.TITEL
    " />
              </div>
              <div>
                <label for="aendNamensvorsatz" class="text-sm mb-2 block relative">
                  Namensvorsatz
                  <span class="absolute top-0.5 right-0 cursor-pointer hover:text-primary transition-all duration-300"
                    id="tooltipButtonnamensvorsatz"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                      fill="currentColor" class="w-4 h-4">
                      <path fill-rule="evenodd"
                        d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-6 3.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.293 5.293a1 1 0 1 1 .99 1.667c-.459.134-1.033.566-1.033 1.29v.25a.75.75 0 1 0 1.5 0v-.115a2.5 2.5 0 1 0-2.518-4.153.75.75 0 1 0 1.061 1.06Z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                  <div id="tooltipContentnamensvorsatz" role="tooltip" data-tooltip-placement="top"
                    class="tooltip invisible absolute z-10 inline-block rounded-md text-gray-900 bg-gray-100 px-3 py-2 text-sm font-medium opacity-0 shadow-sm transition-all duration-300">
                    z. B. von, von der, zum, ...
                    <div class="tooltip-arrow" data-popper-arrow id="arrow"></div>
                  </div>
                </label>
                <input maxlength="100" type="text" id="aendNamensvorsatz" :class="setStatusClassNAMEVS"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.NAMEVS
    " />

              </div>
            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div class="relative">
                <label for="aendVorname" class="text-sm mb-2 block">
                  Vorname
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendVorname" :class="setStatusClassVORNAME"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten
    .VORNAME
    " :pattern="getNamePattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Vorname angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="relative">
                <label for="aendName" class="text-sm mb-2 block">
                  Nachname
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendName" :class="setStatusClassNACHNAME"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.NAME
    " :pattern="getNamePattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Name angegebene Wert muss gültig sein.
                </span>
              </div>
            </div>
            <div :class="showFamStand ? 'grid xl:grid-cols-2 sm:gap-5' : 'grid xl:grid-cols-1 sm:gap-5'">
              <div class="relative">
                <label for="aendNamenszusatz" class="text-sm mb-2 block relative">
                  Namenszusatz
                  <span class="absolute top-0.5 right-0 cursor-pointer hover:text-primary transition-all duration-300"
                    id="tooltipButtonnamenszusatz"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                      fill="currentColor" class="w-4 h-4">
                      <path fill-rule="evenodd"
                        d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-6 3.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.293 5.293a1 1 0 1 1 .99 1.667c-.459.134-1.033.566-1.033 1.29v.25a.75.75 0 1 0 1.5 0v-.115a2.5 2.5 0 1 0-2.518-4.153.75.75 0 1 0 1.061 1.06Z"
                        clip-rule="evenodd" />
                    </svg>

                  </span>
                  <div id="tooltipContentnamenszusatz" role="tooltip" data-tooltip-placement="top"
                    class="tooltip invisible absolute z-10 inline-block rounded-md text-gray-900 bg-gray-100 px-3 py-2 text-sm font-medium opacity-0 shadow-sm transition-all duration-300">
                    z. B. Graf, Freifrau, ...
                    <div class="tooltip-arrow" data-popper-arrow id="arrow"></div>
                  </div>
                </label>
                <input maxlength="100" type="text" id="aendNamenszusatz" :class="setStatusClassNAMEZUS"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten
    .NAMEZUS
    " />
              </div>

              <div class="relative" v-if="showFamStand">
                <label for="aendPersoenlichedaten" class="text-sm mb-2 block">
                  Persoenlichedaten
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setFamStand" class="select" :class="setStatusClassFAMSTD" required
                  :options="sFamStandOptions" :default="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.FAMSTD
    " :oldDefault="store.olddraft.sService.Entwuerfe.AenderungPersoenlichedaten
    .FAMSTD
    " :tabindex="2" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Es muss ein gültiger Wert ausgewählt werden.
                </span>
              </div>
            </div>
            <div class="grid lg:grid-cols-2 gap-x-8">


              <div v-if="store.data.Stammdaten.SVNR.length <= 0" class="relative">
                <label for="sozialversicherungsnummer" class="text-sm mb-2 block">
                  Sozialversicherungsnummer
                  <span class="absolute top-0.5 right-0 cursor-pointer hover:text-primary transition-all duration-300"
                    id="tooltipButtonsozialversicherungsnummer"><svg xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                      <path fill-rule="evenodd"
                        d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-6 3.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.293 5.293a1 1 0 1 1 .99 1.667c-.459.134-1.033.566-1.033 1.29v.25a.75.75 0 1 0 1.5 0v-.115a2.5 2.5 0 1 0-2.518-4.153.75.75 0 1 0 1.061 1.06Z"
                        clip-rule="evenodd" />
                    </svg>

                  </span>
                  <div id="tooltipContentsozialversicherungsnummer" role="tooltip" data-tooltip-placement="top"
                    class="tooltip invisible absolute z-10 inline-block rounded-md text-gray-900 bg-gray-100 px-3 py-2 text-sm font-medium opacity-0 shadow-sm transition-all duration-300">
                    Sie finden sie auf Ihrem Sozialversicherungsausweis, einer Bestätigung der Rentenkasse oder der
                    Mitgliedsbestätigung Ihrer Krankenkasse.
                    <div class="tooltip-arrow" data-popper-arrow id="arrow"></div>
                  </div>
                </label>
                <input maxlength="255" type="text" id="sozialversicherungsnummer"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8"
                  v-model="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.SVNR" inputmode="numeric" />
              </div>

              <div v-if="store.data.Stammdaten.STEUERIDNR.length <= 0" class="relative">
                <label for="steuer-id" class="text-sm mb-2 block">Steuer-ID</label>

                <input maxlength="255" type="text" id="steuer-id"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8  invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.STEUERIDNR" inputmode="numeric"
                  :pattern="validSteuerid ? '.*' : 'invalid'" />
                <span class="text-red-500 text-sm absolute bottom-2 left-0 min-w-max" v-if="!validSteuerid">
                  Bitte geben Sie eine gültige Steuer-ID ein.
                </span>

              </div>

            </div>
            <div>
              <label class="text-sm mb-2 block">Dokumente</label>
              <DokumenteHochladen :formId="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.uuid" :defaultData="store.draft.sService.Entwuerfe.AenderungPersoenlichedaten.uploadsDokumente
    " maxfiles="10" minfiles="0" infoText="AenderungPersdata" docArt="Docs" @status="docDocStatus" @isOk="docDocOk"
                @newfiles="newfiles" />
            </div>


            <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
              <ButtonSendDataSelfService :disabled="setStatusClass !== 'modified' ||
    !validVORNAME() ||
    !validNAME() ||
    (!validSteuerid && store.data.Stammdaten.STEUERIDNR.length > 0)
    " @finished="sendDataFinished" />
              <ButtonResetServiceData :disabled="setStatusClass !== 'modified'" @reset="resetData" />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
