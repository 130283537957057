<template>
  <div class="relative z-0 mb-2 w-full group" :class="statusClass" :required="required">

    <input type="date" :name="name" :id="name"
      class="w-full p-3 border  rounded-md mb-8 invalid:border-red-500 invalid:!bg-red-50 focus:border-tertiary/50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 transition-all"
      :class="(isEmpty && required) ? '!border-red-500 bg-red-50 text-red-500 invalid:text-red-500 invalid:!bg-red-50 invalid:border-red-500' : 'border-secondary/50 text-tertiary'"
      :placeholder="placeholder" :disabled="disabled" v-model="this.date" @change="validateDate" @keyup="shortenDate" />
    <div class="absolute -bottom-4 text-primary text-sm">{{ meldung }}</div>
  </div>
</template>

<script>
/** Feld zum Erfassen eines Datums
 * @displayName Eingabefeld Datum
 */
export default {
  name: "DateInput",

  data() {
    return {
      date: this.modelValue,
      meldung: "",

    };
  },
  emits: ["update:modelValue", "dateChecked"],
  computed: {
    isEmpty: function () {
      return this.date == '';
    }

  },
  watch: {
    modelValue: {
      handler(newData, oldData) {
        this.$servertalk.doLog('date')
        this.$servertalk.doLog(this.modelValue)
        if (this.modelValue == '') {
          this.date = '';
        }
      }
    }
  },
  props: {
    /**
     * Feldname (HTML-Attribut)
    */
    name: {
      type: String,
      default: 'dateInput',
      required: false,
    },
    /**
     * Klasse des Feldwrappers
    */
    statusClass: {
      type: String,
      default: '',
      required: false,
    },
    /**
     * HTML-Attribut des Input-Feldes
    */
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * HTML-Attribut des Input-Feldes
    */
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * Property für die Verwendung von v-model in der rufenden Komponente
    */
    modelValue: {
      type: String,
      default: "",
      required: false,
    },
    /**
     * Frühester Wert, Eingabe in Tagen ab heute
    */
    dmin: {
      type: String,
      required: false,
      default: () => {
        var date = new Date();
        date.setDate(date.getDate() - 7);
        return date.toISOString().slice(0, 10);
      },
    },
    /**
     * Spätester Wert, Eingabe in Tagen ab heute
    */
    dmax: {
      type: String,
      required: false,
      default: () => {
        var date = new Date();
        date.setDate(date.getDate() + 7);
        return date.toISOString().slice(0, 10);
      },
    },
    /**
     * HTML-Attribut des Input-Feldes
    */
    placeholder: {
      type: String,
      required: false,
      default: "Datum",
    },
  },
  mounted() {
    if ("" !== this.date) {
      this.validateDate();
    }
  },
  methods: {
    stringToDate(s) {
      if (s.length == 0) {
        return new Date();
      }
      else if (s[0] == '-' || s[0] == '+') {
        var date = new Date();
        return new Date(date.setDate(date.getDate() + parseInt(s)));
      }
      else {
        var date = new Date(s);
        return new Date(date);
      }
    },
    shortenDate() {

    },
    validateDate() {

      if (this.date.length > 10) {
        //this.date = this.date.substring(0,10);
        let da = this.date.split('-');
        da[0] = da[0].substring(0, 4);
        this.date = da.join('-');

        this.$servertalk.doLog('Date zu lang ' + this.date.substring(0, 10));

      }

      let err = "false";
      let ok = "" !== this.date;
      let d = 0;
      let _dmin = this.stringToDate(this.dmin);//new Date(this.dmin);
      let _dmax = this.stringToDate(this.dmax);//new Date(this.dmax);
      //console.log('_din', _dmin);
      let doptions = { year: "numeric", month: "short", day: "numeric" };
      ok =
        ok &&
        /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(this.date);
      if (ok) {
        d = new Date(this.date);
        ok = d instanceof Date;
      }
      if (ok) {
        ok = d > _dmin && d < _dmax;
      }

      this.$servertalk.doLog("this.date " + ok + " " + this.date);
      if (!ok) {
        if (_dmin !== false && _dmax !== false) {
          this.meldung =
            "Bitte geben Sie ein gültiges Datum zwischen dem " +
            _dmin.toLocaleDateString("de-DE", doptions) +
            " und dem " +
            _dmax.toLocaleDateString("de-DE", doptions) +
            " an.";
        } else {
          this.meldung = "Bitte geben Sie ein gültiges Datum an.";
        }

        err = "true";
      } else {
        //if (/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(this.birthday)) {
        this.meldung = "";
        //        } else {
        //          this.meldung = 'Bitte geben Sie ein gültiges Datum an.';
        //          err = 'true';
        //        }
      }
      /**
       * Das Datum wurde geprüft
       * @property {string} err "false"/"true"
       */
      this.$emit("dateChecked", err);

      if (err == "false" && this.modelValue != this.date) {

        this.$servertalk.doLog("emit " + err + " " + this.date);
        /**
         * Das Datum wurde geprüft
         * @property {String} this.date Datum zur Verwendung via v-model
         */
        this.$emit("update:modelValue", this.date);
      }
    },
  },
};
</script>
