<script setup>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DataVersorgung from "../components/DataVersorgungsDaten.vue";
</script>
<script>
import { store } from "../components/store.vue";
/** View-Komponente zur Anzeige aller Versorgungsdaten gegliedert nach PMNR
 * @displayName Seite Versorgungsdaten
 */
export default {
  name: "MeineVersorgung",
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DataVersorgung,
  },
  data() {
    return {
      store,
    };
  },

  methods: {},
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <div
      class="mt-36 md:mt-48 lg:mt-56 mb-32 max-w-5xl mx-auto px-5 lg:px-0 lg:min-h-[500px]">
      <h1
        class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
        Meine Versorgungsdaten
      </h1>
      <div
        class="intro-content [&>p]:mb-8 max-w-3xl mx-auto lg:text-xl font-light">
        <p>
          Hier können Sie alle wesentlichen Informationen rund um Ihre
          betriebliche Altersversorgung auf einen Blick einsehen. Ihre
          Versorgungsdaten werden separat je PM-Nr. dargestellt.
        </p>
      </div>
      <h2
        class="text-gray-700 mb-8 text-2xl font-bold pb-2 border-b border-primary mt-16">
        Übersicht meiner Versorgungsdaten
      </h2>
      <div v-if="typeof store.data.Versorgung == 'object'">
        <div v-if="store.data.Versorgung.PMNR.length >= 0">
          <div
            v-for="(p, index) in store.data.Versorgung.PMNR"
            v-bind:key="index">
            <DataVersorgung :PMNR="index" v-bind:_uid="index" />
          </div>
        </div>
        <div v-else>
          <div>
            <DataVersorgung :PMNR="-1" />
          </div>
        </div>
      </div>
      <div v-else>
        <p class="p-16">
          Es wurden bislang keine Versorgungsdokumente in Ihrem Benutzerprofil
          hinterlegt.
        </p>
      </div>
      <button
        class="mt-8 bg-primary text-white py-4 px-4 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary"
        @click="$router.push({ name: 'versorgungsdokumente' })">
        Zu meinen Versorgungsdokumenten
      </button>
    </div>
  </div>
  <FooterMenu />
</template>
