<template>
  <div>
    <div ref="captureContainer" class="document-container">
      <video ref="video" width="100%" height="100%" autoplay></video>
      <div class="overlay">
        <div class="document-content">
          <!-- Hier kannst du zusätzliche Elemente für die Anzeige hinzufügen -->
        </div>
        <div class="red-border" :style="borderStyle"></div>
      </div>
    </div>
    <label class="custom-upload-btn">
      <span>Foto aufnehmen und hochladen</span>
      <input type="file" @change="captureAndUpload" accept="image/*" />
    </label>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import axios from "axios";

export default {
  data() {
    return {
      mediaStream: null,
      borderStyle: {
        width: "210mm",
        height: "297mm",
        border: "2px solid red",
        position: "absolute",
        top: "0",
        left: "0",
        pointerEvents: "none",
        display: "none", // Startet als nicht sichtbar
      },
    };
  },
  methods: {
    async captureAndUpload() {
      const captureContainer = this.$refs.captureContainer;
      const video = this.$refs.video;

      // html2canvas erstellt ein Bild des Inhalts im Container
      const canvas = await html2canvas(captureContainer);

      // Konvertiere das Canvas in ein Daten-URL-Format
      const dataUrl = canvas.toDataURL("image/jpeg");

      // Erstelle ein Blob-Objekt aus dem Daten-URL
      const blob = this.dataURItoBlob(dataUrl);

      // Erstelle ein FormData-Objekt und füge den Blob hinzu
      const formData = new FormData();
      formData.append("document", blob, "document.jpg");

      // Sende das FormData-Objekt an den Server (ersetze 'upload-url' durch die tatsächliche URL)
      try {
        const response = await axios.post("upload-url", formData);
        //console.log("Upload erfolgreich:", response.data);
      } catch (error) {
        //console.error("Fehler beim Upload:", error);
      }
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: "image/jpeg" });
    },
    updateBorderStyle() {
      // Setze die Größe des Rahmens basierend auf der tatsächlichen Größe des Kamerabildes
      const video = this.$refs.video;
      this.borderStyle.width = `${video.videoWidth}px`;
      this.borderStyle.height = `${video.videoHeight}px`;
      this.borderStyle.display = "block"; // Setze auf sichtbar, nachdem die Größe festgelegt wurde
    },
  },
  beforeDestroy() {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  },
  mounted() {
    // Füge einen Event Listener hinzu, um den Rahmen nach dem Laden des Kamerabildes zu aktualisieren
    const video = this.$refs.video;
    video.addEventListener("loadedmetadata", this.updateBorderStyle);
  },
};
</script>

<style scoped>
/* Hier kannst du dein CSS für die Komponente hinzufügen */
.document-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.document-content {
  width: 210mm;
  height: 297mm;
  border: 1px solid #ccc;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.red-border {
  width: 210mm;
  height: 297mm;
  border: 2px solid red;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.custom-upload-btn {
  display: inline-block;
  border: 2px solid #3498db;
  color: #3498db;
  background-color: white;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  position: relative;
  z-index: 9999999;
  overflow: hidden;
}
.custom-upload-btn:hover {
  background: #ccc;
  border-color: red;
}
.custom-upload-btn input {
  display: none;
}
</style>
