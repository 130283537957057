<template>
    <div id="app" class="max-w-[1440px] mx-auto leading-relaxed px-5 2xl:px-0">
        <div class="anmeldung" v-if="$route.name == 'login'">
            <div class="text-gray-700 mb-4 text-2xl font-bold pb-2 border-b border-primary mt-8 lg:mt-5 xl:mt-8">
                Hilfe bei der Anmeldung
            </div>
            <div class="block sm:hidden">
                <AccordionItem title="Wie gebe ich auf meinem Smartphone das Geburtsjahr an?"
                    :isOpen="openAccordion === 'jahr'" @toggle="toggleAccordion('jahr')">

                    <img src="assets/img/screenshot-android-jahresauswahl-cropped.jpg" role="img"
                        alt="Screenshot mobile Nutzung der Jahresauswahl" />
                    <div class="p-4">Wie auf dem Screenshot zu sehen klicken Sie bitte auf die Jahreszahl oben um das
                        Geburtsjahr
                        auszuwählen.</div>

                </AccordionItem>
            </div>
            <AccordionItem title="Ich kann mich mit meinem bisherigen Passwort nicht anmelden. Was muss ich tun?"
                :isOpen="openAccordion === 'anmeldung'" @toggle="toggleAccordion('anmeldung')">
                Bitte prüfen Sie Ihren E-Mail-Posteingang und Ihren Spam-Ordner. Wir haben
                Ihnen aufgrund einer Portalumstellung ein neues Passwort zur
                Initialisierung zugesandt. Bitte verwenden Sie keinesfalls Ihr bisheriges
                Passwort.
            </AccordionItem>

            <AccordionItem title="Ich habe mein Passwort vergessen. Was muss ich tun?"
                :isOpen="openAccordion === 'nutzung'" @toggle="toggleAccordion('nutzung')">
                <div class="check">
                    Nutzen Sie die Funktion „<router-link to="/PasswortVergessen">Passwort vergessen</router-link>“. Sie
                    erhalten dann innerhalb
                    der nächsten 60 Minuten ein neues Passwort per E-Mail.
                </div>
            </AccordionItem>
            <AccordionItem
                title="Das Passwort, das ich von Ihnen per E-Mail erhalten habe, funktioniert nicht. Was muss ich tun?"
                :isOpen="openAccordion === 'passwort'" @toggle="toggleAccordion('passwort')">
                <ul class="check ml-7 list space-y-4">
                    <li>Bitte prüfen Sie erneut die Eingabe. </li>
                    <li>Fehlerhafte Eingaben müssen ggf. vor einem erneuten Versuch gelöscht werden. Dazu bitte Strg+F5
                        oder
                        Strg+Alt+E gleichzeitig drücken. </li>
                    <li>Bitte verwenden Sie die gleiche E-Mailadresse, an die wir das Initialisierungskennwort gesandt
                        haben.
                    </li>
                    <li> Sofern Sie das Passwort kopiert haben, versuchen Sie es bitte erneut über eine manuelle
                        Eingabe.
                    </li>
                    <li> Sollten alle vorgenannten Optionen nicht funktionieren, fordern Sie bitte über die Funktion
                        „Passwort
                        vergessen“ ein neues Passwort an. Sie erhalten dann innerhalb der nächsten 60 Minuten ein neues
                        Passwort
                        per E-Mail.</li>
                </ul>

            </AccordionItem>
            <AccordionItem
                title="Ich habe bei der Anmeldung alle Daten korrekt eingegeben, die Schaltfläche „Anmelden“ bleibt jedoch grau. Was muss ich tun?"
                :isOpen="openAccordion === 'korrekt'" @toggle="toggleAccordion('korrekt')">
                <ul class="check ml-7 list space-y-4">
                    <li>Hier kann es sich um ein Problem mit dem Gültigkeitszeitraum des JavaWebTokens handeln. Bitte
                        überprüfen Sie, dass die Uhrzeit Ihres Rechners (PC oder mobiles Endgerät) korrekt eingestellt
                        ist.
                    </li>
                    <li>Sollte die Uhrzeit richtig eingestellt sein, bitte Strg+F5 oder Strg+Alt+E gleichzeitig drücken
                        und
                        die Anmeldedaten nochmals eingeben.</li>
                </ul>

            </AccordionItem>
        </div>
        <div class="rechte-seite">
            <div class="wo" v-if="$route.name != 'login'">
                <div class="text-gray-700 mb-4 text-2xl font-bold pb-2 border-b border-primary mt-8 lg:mt-5 xl:mt-8">
                    Wo finde ich was?
                </div>
                <AccordionItem title="Ihre betriebliche Altersversorgung ganz einfach digital verwalten."
                    :isOpen="openAccordion === 'wowas'" @toggle="toggleAccordion('wowas')">
                    <ul class="check ml-7 list space-y-4">
                        <li>
                            Ihre persönlichen Daten wie Anschrift, Geburtsdatum oder Ihre
                            Bankverbindung können Sie unter dem Menüpunkt
                            <router-link active-class="font-bold" to="/persoenlichedaten" class="">
                                „Meine Daten“
                            </router-link>
                            einsehen oder bei Bedarf ändern.
                        </li>
                        <li>
                            Unter dem Menüpunkt
                            <router-link active-class="font-bold" to="/versorgungsdaten" class="">
                                „Meine Versorgung“
                            </router-link>
                            können Sie alle wesentlichen Informationen rund um Ihre betriebliche
                            Altersversorgung abrufen.
                        </li>
                        <li>
                            Wichtige Dokumente wie z.B. Ihre letzte Rentenabrechnung,
                            Steuerbescheinigungen oder aktuelle Standmitteilungen können Sie ganz
                            bequem unter dem Menüpunkt
                            <router-link active-class="font-bold" to="/versorgungsdokumente" class="">
                                „Versorgungsdokumente"
                            </router-link>
                            einsehen und bei Bedarf auf Ihrem elektronischen Endgerät speichern.
                        </li>
                        <li>
                            Sie haben ein Anliegen oder Änderungswunsch zu Ihrer Versorgung? Unter
                            dem Menüpunkt
                            <router-link active-class="font-bold" to="/auftrag-an-pensus" class="">
                                „Auftrag an Pensus“
                            </router-link>
                            finden Sie alle wichtigen Online-Formulare, um direkt mit uns in
                            Kontakt zu treten und Aufträge an uns zu übermitteln. Sobald wir Ihren
                            Auftrag bearbeitet haben, erhalten Sie eine Nachricht per E-Mail
                            sofern Sie uns diese mitgeteilt haben.
                        </li>
                    </ul>
                </AccordionItem>
            </div>
            <div class="nutzung">
                <div class="text-gray-700 mb-4 text-2xl font-bold pb-2 border-b border-primary mt-8">
                    Hilfe bei der Nutzung
                </div>
                <AccordionItem
                    title="Wenn ich einen neuen Auftrag einreichen will, wird mir auf der Startseite folgender Hinweis angezeigt: „Ihre Daten werden aktuell an anderer Stelle bearbeitet“. Was bedeutet das?"
                    :isOpen="openAccordion === 'stelle'" @toggle="toggleAccordion('stelle')">
                    Dieser Hinweis bedeutet, dass Sie einen oder mehrere Änderungsaufträge erteilt haben. Der
                    Bearbeitungsstatus
                    wird Ihnen auf der Übersichtsseite angezeigt. Sie können während einer Sitzung beliebig viele
                    Änderungsaufträge
                    einreichen. Wenn Sie sich dann aber aus dem Portal ausloggen, müssen zunächst die eingereichten
                    Aufträge
                    von
                    uns
                    bearbeitet werden, bevor Sie im Rahmen einer neuen Sitzung weitere Aufträge einreichen.
                    Selbstverständlich
                    können Sie aber bis dahin Ihre Daten einsehen und Dokumente downloaden. Sobald die Aufträge
                    bearbeitet
                    wurden,
                    erhalten Sie eine Nachricht von uns per E-Mail.
                </AccordionItem>
                <AccordionItem
                    title="In der Rubrik „Meine Versorgung“ werden unter „Versorgungsdokumente“ keine Inhalte angezeigt. Woran liegt das?"
                    :isOpen="openAccordion === 'doku'" @toggle="toggleAccordion('doku')">
                    In diesem Fall sind Sie vermutlich Neurentner oder ein neu aufgenommener Anwärter. Dokumente können
                    demzufolge erst dann bereitgestellt werden, wenn Sie von uns erzeugt wurden. Naturgemäß kann es
                    deshalb
                    zirka 1 bis 2 Monate dauern, bis die ersten Dokumente wie Abrechnungen,
                    Kontoauszüge/Standmitteilungen,
                    Steuerbescheinigungen etc. abgerufen werden können.
                </AccordionItem>
                <AccordionItem
                    title=" Meine Versorgungsdokumente werden mir nach dem Download auf dem Bildschirm korrekt angezeigt, beim Ausdrucken werden bestimmte Inhalte jedoch geschwärzt. Woran liegt das?"
                    :isOpen="openAccordion === 'pdf'" @toggle="toggleAccordion('pdf')">
                    Wenn Sie Dokumente mit dem Acrobat Reader öffnen, kann es aufgrund der Voreinstellungen im Acrobat
                    Reader dazu kommen, dass vertrauliche Informationen beim Ausdrucken automatisiert geschwärzt werden.
                    Bitte ändern Sie die Voreinstellung oder öffnen Sie das Dokument vor dem Druck mit Ihrem Browser
                    oder
                    einer anderen Software.
                </AccordionItem>
            </div>
        </div>
    </div>
</template>

<script>
import AccordionItem from "./FAQAccordion.vue";
export default {
    components: {
        AccordionItem,
    },
    data() {
        return {
            openAccordion: null,
        };
    },
    methods: {
        toggleAccordion(accordionTitle) {
            this.openAccordion =
                this.openAccordion === accordionTitle ? null : accordionTitle;
        },
    },
};
</script>