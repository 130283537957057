<template>
  <div id="password" class="relative z-0 mb-6 w-full group block">
    <input
      maxlength="255"
      :type="type"
      name="floating_password"
      id="floating_password"
      class="block py-2.5 px-0 w-full text-xl font-normal tracking-wide text-tertiary bg-transparent border-0 border-b-2 border-secondary/50 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer mb-2"
      placeholder=" "
      required
      v-model="this.passwd"
      @keyup="validatePassword" />

    <label
      for="floating_password"
      aria-label="Passwort"
      class="absolute left-0 text-xl font-light text-secondary dark:text-secondary transition-all duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6">
      Passwort
    </label>
    <button
      data-tooltip-target="tooltipContent"
      data-tooltip-placement="bottom"
      data-tooltip-style="light"
      id="tooltipButton"
      type="button"
      role="button"
      class="mx-auto absolute top-4 right-1"
      @click="
        showPassword();
        toggle = !toggle;
      ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        v-if="toggle"
        class="h-6 w-6 stroke-secondary hover:stroke-primary transition-all"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        v-if="!toggle"
        class="h-6 w-6 stroke-secondary hover:stroke-primary transition-all"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
      </svg>
    </button>
    <div
      id="tooltipContent"
      data-tooltip-placement="bottom"
      role="tooltip"
      class="absolute z-40 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-gray-100 border-primary rounded-lg shadow-sm opacity-0 tooltip text-center w-40">
      {{ btnText }}
      <div id="arrow" data-popper-arrow="true"></div>
    </div>
   
  </div>
</template>

<script>
import { store } from "./store.vue";
import { Tooltip } from "flowbite";
import { onMounted } from "vue";
/** Inputfeld zur Eingabe des Passwortes
 * @displayName Benutzerpasswort Eingabefeld
 */
export default {
  setup() {
    onMounted(() => {
      const $targetEl = document.getElementById("tooltipContent");
      const $triggerEl = document.getElementById("tooltipButton");

      const options = {
        placement: "bottom",
        triggerType: "hover",
      };
      const tooltip = new Tooltip($targetEl, $triggerEl, options);

      tooltip.hide();
    });
  },
  data() {
    return {
      type: "password",
      btnText: "Passwort anzeigen",
      toggle: "true",
      store,
      passwd: "",
    };
  },
  mounted() {
    //if ('' !== this.passwd) {
    this.validatePassword();
    //}
  },
  watch: {
    passwd(newValue) {
      this.validatePassword();
    },
  },
  //     pass:this.Password,
  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "Passwort verstecken";
      } else {
        this.type = "password";
        this.btnText = "Passwort anzeigen";
      }
    },
    validatePassword() {
      this.$servertalk.doLog("validate");
      let err = false;
      if ("" == this.passwd.trim()) {
        this.meldung = "Bitte geben Sie das Passwort an.";
        err = true;
      }
      store.passwd = this.passwd.trim();
      /**
       * Das Passwort wurde auf Leerstring geprüft
       * @property {boolean} err true wenn fehlerhaft
       */
      this.$emit("passwordChecked", err);
    },
  },
};
</script>

