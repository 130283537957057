<template>
    <div class="cookie h-[100svh] w-full bg-black/80 absolute left-0 z-[999999] flex items-center" v-if="isOpen">
        <div class="cookie_content max-w-xl mx-5 sm:mx-auto text-xl bg-white p-8 text-tertiary">
            Wir verwenden Cookies in unserem Serviceportal. Ohne diese ist eine Nutzung der Anwendung nicht möglich.Mit
            einem Klick auf auf Button <span class="font-bold">Akzeptieren</span> willigen Sie in deren Verwendung ein
            und stimmen unseren
            <a href="https://www.pensus.de/datenschutz/" target="_blank" class="underline">Datenschutzbestimmungen</a>
            zu.<br />
            <a href="#"
                class="cookie_button accept inline-block bg-primary px-4 py-2 text-white mt-4 hover:bg-secondary transition-all duration-500"
                v-on:click="accept($event)">
                {{ buttonTextAccept }}
            </a>
        </div>

    </div>
</template>

<script>
export default {
    name: 'CookieMessage',
    props: {
        buttonTextAccept: {
            type: String,
            default: 'Akzeptieren'
        },

    },
    data() {
        return {
            isOpen: false
        }
    },
    created() {
        if (!this.getGDPR() === true) {
            this.isOpen = true;
        }
    },
    methods: {
        getGDPR() {
            return localStorage.getItem('GDPR_accepted');
        },
        accept(e) {
            e.preventDefault();
            this.isOpen = false;
            localStorage.setItem('GDPR_accepted', true);
        },
    }
}
</script>