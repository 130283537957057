<template>
  <div class="relative z-0 mb-6 w-full group">
    <input
      maxlength="255"
      type="email"
      name="floating_user"
      id="floating_user"
      class="block focus:font-normal py-2.5 px-0 w-full text-xl font-normal tracking-wide text-tertiary bg-transparent border-0 border-b-2 border-secondary/50 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer placeholder:text-tertiary"
      placeholder=" "
      required
      v-model="this.email"
      @keyup="validateEmail" />
    <label
      for="floating_user"
      aria-label="E-Mail Adresse"
      class="absolute left-0 text-xl font-light text-secondary transition-all duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6">
      E-Mail Adresse
    </label>
    <p class="text-primary max-[380px]:text-xs text-sm">{{ meldung }}</p>
    <svg
      class="checkmark hidden"
      :class="{ active: meldung == '1' }"
      role="img"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path
        class="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
</template>

<script>
import { store } from "./store.vue";
/** Inputfeld zur Eingabe des Benutzernamens (bzw. der Mail-Adresse) mit Plausibilitätsprüfung
 * @displayName Benutzername Eingabefeld
 */
export default {
  name: "UserName",

  data() {
    return {
      store,
      email: "",
      meldung: "",
    };
  },
  mounted() {
    //if ('' !== this.email) {
    this.validateEmail();
    //}
  },
  watch: {
    email: {
      handler(newData) {
        this.validateEmail();
      },
    },
  },
  methods: {
    validateEmail() {
      let err = false;
      if ("" == this.email.trim()) {
        this.meldung = "Bitte geben Sie Ihre E-Mail / Nutzerkennung ein";
        err = true;
      } else {
        if (
          // Regex für E-Mail Adresse
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
            this.email
          ) || // Regex für Erstanmeldung ohne Mail-Adresse (Name + '#' + Ziffernfolge) [Name mind. 3 Zeichen, darf Space und Bindestrich enthalten, mind. 3 Ziffern]
          /^[A-Za-z\-\s]{3,}[#][0-9]{1,}$/.test(this.email.trim())
        ) {
          this.meldung = "";
          store.email = this.email.trim();
          store.user = this.email.trim();
        } else {
          this.meldung = "Bitte geben Sie eine gültige E-Mail Adresse an.";
          err = true;
        }
      }
      /**
       * Der Benutzername wurde geprüft
       * @property {boolean} err true wenn fehlerhaft
       */
      this.$emit("mailChecked", err);
    },
  },
};
</script>
<style scoped>
.checkmark.active {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: absolute;
  top: 13px;
  right: 5px;
  margin: 0 auto;
}
.checkmark.active .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 4;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark.active .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
</style>
