<template>
  <LoginPrompt />
  <Cookiebanner />
</template>

<script>
import LoginPrompt from "../components/LoginPrompt.vue";
import Cookiebanner from "../components/Cookiebanner.vue";
/** View-Komponente zur Anmeldung in den internen Bereich
 * @displayName Seite Anmeldung
 */
export default {
  name: "LoginPage",
  components: {
    // myRouter: router,
    LoginPrompt,
    Cookiebanner
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
