<template>
  <div
    class="logout-timer hidden xl:flex"
    :class="{ 'text-red-500': expectLogout }"
  >
    Sitzungsende in&nbsp;
    <span>{{ minDisp }}:{{ secDisp }}</span>
  </div>
</template>

<script>
/** Komponente zum Anstoßen eines automatischen Logouts bei Untätigkeit über einen definierten Zeitraum
 * @displayName Timer-Komponente
 */
export default {
  name: "TimerComponent",
  props: {
    /**
     * Wird in Parent-Komponente bei jedem Klick auf ResetTimer geändert und triggert Timer-Reset
     */
    timeResetToggler: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    interval: undefined,
    end: Date.now() + 1000 * 60 * 20,
    current: Date.now(),
  }),
  watch: {
    time() {
      /**
       * Abmelden aufgrund anhaltender Untätigkeit auf der Seite
       */
      if (this.time <= 0) this.$emit("loggedOutByTime");
    },
    timeResetToggler() {
      // Trigger Reset bei Wertänderung in Parent-Komponente
      this.resetTimer();
    },
  },
  computed: {
    time: {
      get() {
        return Math.round((this.end - this.current) / 1000);
      },
      set(d) {
        this.current = d;
      },
    },
    minDisp() {
      return Math.floor(this.time / 60) < 10
        ? "0" + Math.floor(this.time / 60)
        : Math.floor(this.time / 60);
    },
    secDisp() {
      return this.time % 60 < 10 ? "0" + (this.time % 60) : this.time % 60;
    },
    expectLogout() {
      return this.time < 60;
    },
  },

  methods: {
    countDown() {
      this.current <= 0
        ? clearInterval(this.interval)
        : (this.time = Date.now());
    },
    resetTimer() {
      this.end = Date.now() + 1000 * 60 * 20;
    },
  },
  created() {
    this.resetTimer;
    this.interval = setInterval(this.countDown, 1000);
  },
  mounted() {
    window.addEventListener("keypress", this.resetTimer);
    window.addEventListener("click", this.resetTimer);
  },
};
</script>
