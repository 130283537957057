<template>
  <div
    class="reset-component cursor-pointer ml-2 mr-8"
    @click="resetTimer"
  ></div>
</template>

<script>
/** Komponente zum Anfordern eines Timer-Resets
 * @displayName Reset Timer
 */
export default {
  name: "ResetTimerComponent",
  data: () => ({}),
  methods: {
    resetTimer() {
      /**
       * Event für das Zurücksetzen des Timers
       */
      this.$emit("resetTimerTriggered"); // Event ist an Komponente gebunden im Parent nutzbar
    },
  },
};
</script>
