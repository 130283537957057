<template>
  <div class="lg:flex lg:items-normal min-[1921px]:h-[960px] items-stretch">
    <form class="p-8 md:p-20 prose max-w-full lg:max-w-3xl">
      <h1 class="text-3xl lg:text-4xl font-bold text-secondary mb-4 tracking-wider">
        Sie haben Ihr Passwort vergessen?
        <br />
        Gar kein Problem!
      </h1>
     
      <h2 class="text-lg text-tertiary mb-8 font-light">
        Geben Sie hier Ihre
        <strong>E-Mail Adresse</strong>
        sowie das
        <strong>Geburtsdatum</strong>
        ein und Sie erhalten bei korrekter Eingabe von uns im ersten Schritt
        eine Bestätigung per E-Mail. Parallel wird Ihre Anfrage an den
        Kundenservice von Pensus weitergeleitet.
        <br />
        Von dort erhalten Sie innerhalb der nächsten 60 Minuten eine zweite
        Nachricht mit Ihrem neuen Initialpasswort für Ihre Anmeldung.
        <br />
        <br />
        <strong class="text-primary">
          Solange Sie kein neues Passwort erhalten haben behält Ihr altes
          Passwort seine Gültigkeit
        </strong>
        .
      </h2>
      <div v-if="isWartung" class="p-5 text-primary border border-primary bg-primary/10 rounded-md my-5 max-w-3xl mx-auto">{{
        messageWartung }}</div>
      <div class="w-96" v-bind:class="submitted == 'true' ? 'hidden' : ''">
        <UserName @mailChecked="mailCheckFinished" />
        <UserBirthday @birthdateChecked="birthdateCheckFinished" />
        <p class="text-primary text-sm">{{ meldung }}</p>
        <div class="text-xs py-2 px-4 bg-slate-50">
          Sie haben Ihr Passwort doch nicht vergessen?
          <br />
          <a :href="'/'" class="text-primary text-xs block rounded-md mt-2 underline hover:text-tertiary transition">
            Hier geht es zur Anmeldung
          </a>
        </div>

        

        <button :disabled="store.isReadOnly || errorEmail == 'true' || errorBirthdate == 'true'" @click="sendRequest" type="submit"
          v-bind:class="errorEmail == 'true' || errorBirthdate == 'true' ? 'disabled' : ''
        "
          class="disabled:bg-secondary disabled:cursor-not-allowed bg-primary hover:bg-secondary font-body font-bold text-md w-full py-3 text-center transition-all duration-500 w-100 my-6 tracking-widest text-white rounded-md">
          Anfrage absenden
        </button>
      </div>
      <div class="font-light text-lg" v-bind:class="submitted != 'true' ? 'hidden' : ''">
        <strong>Ihre Anfrage wurde versandt.</strong>
        <br />
        Sofern Sie eine
        <strong>gültige E-Mail Adresse</strong>
        und das
        <strong>korrekte Datum</strong>
        eingegeben haben, erhalten Sie innerhalb der nächsten Minuten eine
        Bestätigungs-Mail. Bitte prüfen Sie ggf. auch
        <strong>Ihren Spam-Ordner</strong>
        .
        <br />
        <br />
        <div>
          <a :href="'/'"
            class="bg-primary hover:bg-secondary font-body font-bold text-md w-full py-3 text-center transition-all duration-500 px-4 my-6 tracking-widest text-white rounded-md">
            Hier geht es zurück zur Anmeldung
          </a>
        </div>
      </div>
    </form>
    <div class="max-[1920px]:min-h-[100svh] hidden lg:block">
      <img class="max-[1920px]:min-h-full object-cover" src="/assets/img/passwort-vergessen-lock-laptop.jpg" />
    </div>
  </div>
</template>

<script>
import UserName from "./UserName.vue";
import UserBirthday from "./UserBirthday.vue";
import { store } from "./store.vue";
/** Anwendung zum Anfordern des Zurücksetzens eines Passwortes
 * @displayName Passwort vergessen
 */
export default {
  data() {
    return {
      store,
      meldung: "",
      errorEmail: "true",
      errorBirthdate: "true",
      submitted: "false",
    };
  },
  components: {
    UserName,
    UserBirthday,
  },
  mounted: function () {
    //this.$servertalk.doLog('mounted');
    //if (this.$servertalk.restoreStore()) this.$router.push("/startseite");
  },
  computed: {
    isWartung() {
      let wartung = false;
      let omessage = store.config?.messages?.DBWartung ?? false;
      let now = new Date();
      if (omessage && Date.parse(omessage.von) < now && Date.parse(omessage.bis) > now) {
        wartung = true;
      }
      return wartung;
    },
    messageWartung() {
      let message = '';
      if (this.isWartung) {
        message = store.config?.messages?.DBWartung?.message ?? '';
      }

      return message;
    }

  },
  methods: {
    sendRequest(e) {
      this.$servertalk.resetPass(this.store.user, this.store.birthday);
      this.$servertalk.doLog(this.store.user + ":" + this.store.birthday);
      /*
        store.decrypthash = this.$CryptoJS
          .SHA256(this.store.user + ":" + this.store.passwd + ":yyyy")
          .toString();
        store.identhash = this.$CryptoJS
          .SHA256(this.store.user + ":" + this.store.passwd + ":xxxx")
          .toString();
        this.$servertalk.doLog(store.identhash);
        this.$servertalk.doLog(store.decrypthash);
        this.$servertalk.getDataFromDB(store.identhash, this.processResult);
        this.$servertalk.doLog(store.data);
        this.$servertalk.doLog(store.auth);
        */
      this.submitted = "true";
      e.preventDefault();
    },
    mailCheckFinished(err) {
      this.errorEmail = err;
    },
    birthdateCheckFinished(err) {
      this.errorBirthdate = err;
      this.$servertalk.doLog("bError " + err);
    },
  },
};
</script>
