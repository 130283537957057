<script setup>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DataContactTab from "../components/DataContactTab.vue";
import DatenschutzHinweis from "../components/DatenschutzHinweis.vue";
import { store } from "../components/store.vue";
/** Komponente zum Einbinden des Menüpunktes mit den Kontaktdaten
 * @displayName Seite Kontaktdaten
 */
</script>
<script>
export default {
  name: "KontaktDaten",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
  },
  data() {
    return {
      store,
      statusCurrent: "999",
      meldung: "",
    };
  },
  methods: {},
  beforeCreate: function () {
    this.$servertalk.doLog("# Reading From Local, Create ");
    if (!store.auth) {
      //if (!this.$servertalk.restoreStore())
      this.$router.push("/");
    }
  },
  mount: function () {
    this.$servertalk.doLog("# Reading From Local, Mount");
    if (!store.auth) {
      if (!this.$servertalk.restoreStore()) this.$router.push("/");
    }
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 mb-4 max-w-7xl mx-auto px-5 lg:px-0">
      <div class="max-w-5xl mx-auto mb-24">
        <h1
          class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase"
        >
          Kontaktdaten
        </h1>
        <DataContactTab />
      </div>
    </div>
  </div>

  <FooterMenu />
</template>
