<template>
  <div class="relative z-0 b-2 w-full group">
    <input
      maxlength="255"
      type="date"
      name="floating_date"
      id="floating_date"
      class="block focus:font-normal py-2.5 px-0 w-full text-xl font-normal tracking-wide text-tertiary/50 bg-transparent border-0 border-b-2 border-secondary/50 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
      required
      v-model="this.birthday"
      @change="validateBirthday" />
    <label
      for="floating_date"
      aria-label="Geburtsdatum"
      aria-details="Hier müssen Sie Ihr Geburtsdatum angeben"
      class="absolute left-0 text-xl font-light text-secondary duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6">
      Geburtsdatum
    </label>
    {{ meldung }}
  </div>
</template>

<script>
import { store } from "./store.vue";
/** Inputfeld zum Erfassen des Geburtsdatums mit Plausibilitätsprüfung
 * @displayName Geburtsdatum Eingabefeld
 */
export default {
  name: "UserBirthday",

  data() {
    return {
      store,
      birthday: "",
      meldung: "",
    };
  },
  mounted() {
    if ("" !== this.birthday) {
      this.validateBirthday();
    }
  },
  methods: {
    validateBirthday() {
      let err = false;
      let ok = "" !== this.birthday;
      let d = 0;
      ok =
        ok &&
        /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
          this.birthday
        );
      if (ok) {
        d = new Date(this.birthday);
        ok = d instanceof Date;
      }
      if (ok) {
        let dmin = new Date("1899-01-01");
        let dmax = new Date("2023-01-01");
        ok = d > dmin && d < dmax;
      }

      this.$servertalk.doLog(this.birthday);
      if (!ok) {
        this.meldung = "Bitte geben Sie ein gültiges Datum an.";
        err = true;
      } else {
        //if (/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(this.birthday)) {
        this.meldung = "";
        store.birthday = this.birthday;
        //        } else {
        //          this.meldung = 'Bitte geben Sie ein gültiges Datum an.';
        //          err = 'true';
        //        }
      }
      /**
       * Das eingegebene Geburtsdatum wurde geprüft
       * @property {boolean} err true wenn ungültiges Datum
       */
      this.$emit("birthdateChecked", err);
    },
  },
};
</script>
