<script setup>
import CustomSelect from "./CustomSelect.vue";
</script>

<script>
import { store } from "./store.vue";
/** Komponente zur Anzeige persönlicher Daten des Benutzers ohne Änderungsmöglichkeit
 * @displayName Anzeige persönlicher Daten
 */
export default {
  name: "DataKKTab",

  data() {
    return {
      openIndex: null,
      store,


    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },

  beforeCreate: function () {

    /*
        if (action % 2 == 1) {
          // olddraft wurde initialisiert
          let olddraft = store.olddraft.sService.Entwuerfe.AenderungKrankenkasse;
    
    
    
          //olddraft.KRANKENKASSE = "-1";
          olddraft.KRANKENKASSE = _sKKData.KKNR;
    
          if (_kinderData) {
            olddraft.KILO = "1";
            _kinderData?.KLFDNR.forEach(kind => {
              olddraft.KINDER.push(
                {
                  VORNAME: kind.KVORNAME,
                  NAME: kind.KNAME,
                  GEBDAT: kind.KGEBDAT,
                  uploads: [], //füllen, da der Service ansonsten nicht angenommen wird
                  uploadFolgt: "1",
                  vonPensus: true,
                  uuid: this.$servertalk.getUUID(),
                }
    
              )
            })
          }
    
          olddraft.BEITRAGSPFLICHTIG = _sKKData.KKSTATUS; // Dropdown (eigenes)
    
    
          olddraft.uuid = this.$servertalk.getUUID();
        }
        if (action >= 2) {
          // draft wurde initialisiert
          this.$servertalk.copyToDraftSelfService("AenderungKrankenkasse");
        }
        */
  },


  computed: {
    sKKData() {
      let _versorgung = false;


      //es kann auch keine KK geben

      if (typeof store.data.Versorgung === "object") {
        if (store.data.Versorgung.PMNR.length > 1) {
          _versorgung = store.data.Versorgung.PMNR[0]
        }

        else {
          _versorgung = store.data.Versorgung.PMNR
        }
      }


      return _versorgung?.Krankenkasse ?? false;
    },

    sKrankenkasseOptions() {
      let opts = this.$servertalk.getSelectOpts("KRANKENKASSE");
      //finde die private Kasse
      let privNr = opts.findIndex(el => el.value == '999');

      if ((this.sKKData.KKSTATUS == '-1') || (this.sKKData.KKSTATUS == '')) {
        opts = [{ text: "unbekannt", value: "-1" }].concat(opts);
      }
      if (this.sKKData.KKSTATUS != '2') {
        opts = opts.slice(0, privNr - 1).concat(opts.slice(privNr + 1));
      }
      if (this.sKKData.KKSTATUS == '2') {
        opts = [opts[privNr]];
      }
      return opts;
    },

    sBeitragspflichtOptions() {
      let opts = this.$servertalk.getSelectOpts("KK_STATUS");

      if ((this.sKKData.KKSTATUS == '-1') || (this.sKKData.KKSTATUS == '')) {
        opts = [{ text: "unbekannt", value: "-1" }].concat(opts);
      }
      return opts;
    },
    /*
    setStatusClass() {
      // Erhalte den Wert "AenderungKrankenkasse" von $servertalk
      const AenderungKrankenkasse = this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse"
      );
      return AenderungKrankenkasse;
    },
    setStatusClassGEBDAT() {
      // Erhalte den Wert "GEBDAT" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse.GEBDAT"
      );
    },
    setStatusClassKRANKENKASSE() {
      // Erhalte den Wert "KRANKENKASSE" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse.KRANKENKASSE"
      );
    },
    setStatusClassBEITRAGSPFLICHTIG() {
      // Erhalte den Wert "BEITRAGSPFLICHTIG" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG"
      );
    },
    setStatusClassKILO() {
      // Erhalte den Wert "KILO" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse.KILO"
      );
    },
 */

  },
  methods: {
    toggleAccordion(index) {
      if (this.isOpen(index)) {
        this.openIndex = null;
      } else {
        this.openIndex = index;
      }
    },
    isOpen(index) {
      return this.openIndex === index;
    },

  },
};
//console.log(store.getSelectOpts(store.indextable, "ADRANREDE"));
</script>

<template>
  <form>

    <div class="grid xl:grid-cols-2 gap-5">

      <div>
        <label for="aendKrankenkasseAb" class="text-sm mb-2 block schwebend">
          Versichert seit
        </label>
        <div class="schwebend"><input type="date" v-model="sKKData.KKDATAB" disabled
            class="w-full p-3 border border-secondary/50 rounded-md mb-8 ">
        </div>

      </div>
      <div>
      </div>

    </div>

    <div class="grid xl:grid-cols-2 sm:gap-5">

      <div>
        <label for="aendBeitragspflicht" class="text-sm mb-2 block">
          Beitragspflicht

        </label>
        <CustomSelect class="select schwebend" :keyEmpty="-1" required :options="sBeitragspflichtOptions"
          :default="sKKData.KKSTATUS" :tabindex="17" disabled="true" />
      </div>


      <div>
        <label for="aendKrankenkasse" class="text-sm mb-2 block">
          Krankenkasse

        </label>
        <CustomSelect @input="setKrankenkasse" class="select" disabled :options="sKrankenkasseOptions" :keyEmpty="'-1'"
          :default="sKKData.KKNR" :tabindex="15" />
      </div>

    </div>




    <router-link to="/sservice-antrag-aenderungkrankenkasse" custom v-slot="{ navigate }">
      <button @click="navigate" :disabled="store.isReadOnly" role="link" type="submit"
        class="bg-primary text-white py-4 w-72 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary">
        Krankenkassendaten ändern
      </button>
    </router-link>

  </form>
</template>
