<script>
import { store } from "./store.vue";
import packageInfo from "/package.json";
/** Menü im Fußbereich der Seite
 * @displayName Footer Menu
 */
export default {
  data() {
    return {
      currYear: new Date().getFullYear(),
      appVersion: packageInfo.version,
      store,
    };
  },
};
</script>
<template>
  <footer class="flex-shrink-0 py-8 lg:py-16 bg-tertiary relative">
    <div class="container mx-auto max-w-7xl px-5 xl:px-0">
      <div class="pensus-logo-footer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 548.67 128.28" class="w-64 h-auto mb-8"
          alt="Pensus Logo im Fußbereich" role="img">
          <g id="Ebene_1" data-name="Ebene 1" />
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <g>
                <path
                  d="M10.45,32.08H0v-3.12H24.5v11.39c4.06-8.58,10.61-12.95,18.88-12.95,14.2,0,25.74,13.89,25.74,31.05s-12.17,33.39-26.37,33.39c-8.27,0-14.04-4.37-18.25-13.73v41.58h10.3v3.12H0v-3.12H10.45V32.08Zm22.16,2.96c-4.99,4.37-7.64,12.64-7.64,23.87,0,17.94,6.4,29.33,16.69,29.33,4.21,0,7.02-2.18,8.74-6.87,1.4-3.59,2.03-10.92,2.03-23.25,0-14.98-.62-19.81-2.5-22.78-1.87-2.96-4.68-4.37-8.27-4.37-3.28,0-5.93,1.09-9.05,4.06Z"
                  style="fill: #ffffff" />
                <path
                  d="M96.1,54.54v4.52c0,15.45,.31,18.72,2.03,22,2.34,4.68,6.87,7.18,12.79,7.18,9.99,0,17.79-6.87,20.75-18.57h3.74c-2.5,13.11-12.95,22.15-25.43,22.15-15.91,0-30.11-15.13-30.11-32.3s13.73-32.14,29.33-32.14c9.67,0,18.25,4.68,23.25,12.64,2.65,4.21,3.74,7.96,4.06,14.51h-40.41Zm24.18-3.12c0-10.3-.16-11.55-1.25-14.35-1.4-3.9-5.15-6.09-9.99-6.09-5.15,0-9.36,2.03-11.23,5.62q-1.72,3.12-1.72,14.82h24.18Z"
                  style="fill: #ffffff" />
                <path
                  d="M155.86,32.08h-10.45v-3.12h24.5v8.11c5.15-6.71,11.23-9.67,19.5-9.67,6.4,0,12.64,2.34,16.07,6.08,3.43,3.9,4.37,7.49,4.37,19.04v34.64h10.3v3.12h-33.7v-3.12h9.36v-24.03c0-18.57-.62-24.18-2.96-27.3-1.72-2.34-4.52-3.74-7.65-3.74-4.06,0-8.89,2.5-11.39,6.24-3.12,4.37-3.9,11.08-3.9,30.27v18.57h9.36v3.12h-33.86v-3.12h10.45V32.08Z"
                  style="fill: #ffffff" />
                <path
                  d="M268.66,46.59h-2.34c-1.4-9.36-8.58-15.6-17.79-15.6-7.49,0-13.42,4.84-13.42,10.92,0,4.68,3.28,6.71,15.29,8.74,9.67,1.72,13.57,3.59,17.01,7.49,2.65,3.12,4.06,7.8,4.06,12.79,0,12.01-8.58,20.91-19.97,20.91-6.08,0-10.3-1.72-16.38-6.55l-3.74,4.99h-1.72l-.47-23.4h2.18c1.72,6.4,3.28,10.45,5.62,13.89,3.28,4.68,8.74,7.49,14.67,7.49,7.96,0,14.04-5.15,14.04-11.86,0-5.93-3.59-8.27-14.67-10.14-9.67-1.56-13.42-3.12-16.54-6.4-2.96-3.28-4.68-8.11-4.68-13.42,0-10.92,7.8-19.03,18.41-19.03,5.62,0,9.83,1.56,15.13,5.77l2.5-4.99h2.34l.47,18.41Z"
                  style="fill: #ffffff" />
                <path
                  d="M344.63,87.15h10.3v3.12h-24.34v-8.58c-4.37,6.87-11.08,10.14-20.44,10.14-6.87,0-12.64-2.03-16.23-5.62-4.06-3.9-4.84-7.8-4.84-20.91V32.08h-10.45v-3.12h24.5v36.35c0,15.29,.31,18.1,2.18,20.28,1.56,1.72,4.37,2.65,7.8,2.65,5.62,0,10.77-2.81,13.57-7.18,3.12-4.99,3.9-10.14,3.9-27.15v-21.84h-10.45v-3.12h24.5v58.2Z"
                  style="fill: #ffffff" />
                <path
                  d="M404.54,46.59h-2.34c-1.4-9.36-8.58-15.6-17.79-15.6-7.49,0-13.42,4.84-13.42,10.92,0,4.68,3.28,6.71,15.29,8.74,9.67,1.72,13.57,3.59,17.01,7.49,2.65,3.12,4.06,7.8,4.06,12.79,0,12.01-8.58,20.91-19.97,20.91-6.08,0-10.3-1.72-16.38-6.55l-3.74,4.99h-1.72l-.47-23.4h2.18c1.72,6.4,3.28,10.45,5.62,13.89,3.28,4.68,8.74,7.49,14.67,7.49,7.96,0,14.04-5.15,14.04-11.86,0-5.93-3.59-8.27-14.67-10.14-9.67-1.56-13.42-3.12-16.54-6.4-2.96-3.28-4.68-8.11-4.68-13.42,0-10.92,7.8-19.03,18.41-19.03,5.62,0,9.83,1.56,15.13,5.77l2.5-4.99h2.34l.47,18.41Z"
                  style="fill: #ffffff" />
                <polygon points="419.32 39.87 431 31.19 458.6 65.69 546.61 0 548.67 3.51 450.75 90.27 419.32 39.87"
                  style="fill: #ffffff" />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="pensus-footer-infos flex flex-col md:flex-row gap-16 text-white">
        <div class="left">
          <h3 class="text-xl font-bold mb-4">Kontakt</h3>
          Pensus Pensionsmanagement GmbH
          <br />
          Gothaer Platz 10
          <br />
          37083 Göttingen
          <br />
          <div class="mt-5">
            Telefon:
            <a href="tel:+4955170153772" class="underline hover:text-primary transition duration-500">
              +49 551 701537-72
            </a>
          </div>
          <div>
            E-Mail:
            <a href="mailto:info@pensus.de" class="underline hover:text-primary transition duration-500">
              info@pensus.de
            </a>
          </div>
        </div>
        <div class="mitte">
          <h3 class="text-xl font-bold mb-4">Social Media</h3>
          <div class="social-icons flex gap-4 transition">
            <a href="https://www.linkedin.com/company/pensus-pensionsmanagement-gmbh/about/" target="_blank"
              rel="noopener" title="Pensus bei LinkedIn"
              class="transition-all duration-500 hover:text-primary relative after:transition-all after:duration-500 after:absolute after:-bottom-1 after:w-10 after:h-0 after:bg-primary hover:after:h-2">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
                class="svg-inline--fa fa-linkedin-in w-10" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                </path>
              </svg>
            </a>
            <a href="https://www.xing.com/companies/pensuspensionsmanagementgmbh" title="Pensus bei XING"
              target="_blank" rel="noopener"
              class="transition-all duration-500 hover:text-primary relative after:transition-all after:duration-500 after:absolute after:-bottom-1 after:w-10 after:h-0 after:bg-primary hover:after:h-2">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="xing"
                class="svg-inline--fa fa-xing fa-w-12 w-10" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512">
                <path fill="currentColor"
                  d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8 12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1 0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18 12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2 14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8 51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3 6.7 8.5 14.1z">
                </path>
              </svg>
            </a>
          </div>
        </div>
        <div class="rechts lg:ml-auto">
          <img src="/assets/img/din-en-iso-iec-27001_de_regular-rgb_1.600x0.png"
            alt="Wir sind ISO/IEC 27001:2013 zertifiziert" width="1434" height="725"
            class="tuev-siegel w-40 lg:w-64 h-auto" />
        </div>
      </div>
      <div
        class="pensus-footer-links flex flex-col lg:flex-row mt-12 text-white lg:divide-x divide-white lg:leading-none">
        <div class="copyright lg:pr-2 mb-4 lg:mb-0">
          &copy; {{ currYear }} Pensus Pensionsmanagement GmbH
        </div>
        <a href="https://www.pensus.de/impressum/" target="_blank"
          class="text-white underline hover:text-primary transition duration-500 lg:px-2">
          Impressum
        </a>
        <a href="https://www.pensus.de/datenschutz/" target="_blank"
          class="text-white underline hover:text-primary duration-500 transition lg:pl-2">
          Datenschutzerklärung
        </a>
      </div>
    </div>
    <div class="text-xs text-white absolute z-50 bottom-1 right-2">
      <span>v. {{ appVersion }}</span>
      {{ store.datenart }}
      <span></span>
      <span v-if="store.isReadOnly">ro</span>
    </div>
  </footer>
</template>
