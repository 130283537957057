<template>
  <button
    @click="toggleAccordion()"
    class="font-semibold text-lg lg:text-xl trigger py-4 pl-4 pr-8 sm:px-4 first-of-type:bg-gray-100 rounded-md cursor-pointer text-primary relative hover:bg-gray-200 text-left sm:text-center transition flex hyphens-manual items-center justify-between space-x-3 w-full mb-2 duration-500"
    :aria-expanded="isOpen"
    :aria-controls="`collapse${uuid}`"
    :class="{ '!mb-0 rounded-b-none bg-gray-200': isOpen }"
  >
    <slot name="versorgungtitle" />

    <div
      class="toggle relative before:h-1 after:transition-all after:duration-500 before:w-6 before:-right-4 before:bg-primary before:absolute -top-0.5 sm:before:right-0 after:h-1 after:w-6 after:bg-primary after:absolute after:-right-4 sm:after:right-0 after:-rotate-90 before:rounded after:rounded"
    ></div>
  </button>
  <div
    :id="`collapse${uuid}`"
    class="toggle max-h-0 opacity-0 transition-all duration-500 overflow-hidden [&button]:bg-white"
    :class="{ 'max-h-[2000px] !opacity-100 rounded-b-md mb-2': isOpen }"
  >
    <slot name="versorgungcontent" />
  </div>
</template>

<script>
let uuid = 0;
/** Komponente zur Anzeige eines einzelnen Akkordeon-Elements im Bereich "Versorgung"
 * @displayName Akkordeon Bereich "Versorgung"
 */
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>
<style scoped>
.toggle button {
  @apply bg-gray-50 text-tertiary hover:bg-gray-100 lg:pl-8 rounded-none m-0 border-b border-gray-200 last:border-0;
}
.toggle .toggle {
  @apply after:bg-tertiary before:bg-tertiary;
}
</style>
