<script setup>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import FAQContent from "../components/FAQContent.vue";
/** View-Komponente zur Anzeige einer Hilfeseite mit Funktionsbeschreibung
 * @displayName Seite Hilfsbeschreibung
 */
</script>
<script>
export default {
  name: "HilfeServiceportal",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    FAQContent,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  methods: {},
};
</script>
<template>
  <div class="main-wrapper flex-grow">
    <HauptMenue />
    <div class="hero-image-wrapper mb-8 sm:mb-16">
      <img
        class="max-h-[200px] md:max-h-[400px] lg:max-h-[600px]  object-center overflow-hidden object-cover w-full mt-[100px] lg:mt-0"
        src="/assets/img/serviceportal-pensus-pensionsmanagement.jpg"
      />
      <div class="hero-description-block max-w-7xl mx-auto relative">
        <div
          class="hero-inside bg-primary px-5 py-5 lg:py-10 lg:p-12 relative lg:absolute text-white max-w-full lg:max-w-lg lg:bottom-12"
        >
          <h1
            class="text-xl lg:text-4xl text-left font-bold text-white tracking-wider mb-2"
          >
            So funktioniert das Pensus Serviceportal
            <br />
          </h1>
          <h2>
            Verwalten Sie Ihre Pensionsabrechnungen und Bescheinigungen ganz
            einfach online.
          </h2>
        </div>
      </div>
    </div>
    <div class="mb-24 max-w-5xl mx-auto px-5 lg:px-0 text-tertiary">
      <!-- neu ab -->
      <!-- <h3 class="text-lg lg:text-2xl mb-7 font-semibold"> -->
  
      <!-- neu bis -->
      <!-- alt ab
      <h2 class="text-3xl font-bold mb-5">Funktionalitäten:</h2>
      <h3 class="text-2xl mb-2">
        Das Pensus Serviceportal bietet Ihnen den Abruf
      </h3>
      <ul
        class="list-image-[url(http://pensus.dv.int:8080/assets/img/pensus-check.svg)] ml-7 list text-xl mb-8"
      >
        <li>aller ab 2019 erstellten Rentenabrechnungen,</li>
        <li>
          Ihrer jährlichen Lohnsteuerbescheinigung, sofern Sie steuerpflichtige
          Leistungen beziehen,
        </li>
        <li>
          Ihrer Standmitteilung zu Ihrer betrieblichen Altersversorgung, sofern
          Sie noch keine Rente beziehen.
        </li>
      </ul>
      <h3 class="text-2xl mb-2">
        Zusätzlich können Sie im Portal folgende Änderungen durchführen:
      </h3>
      <ul
        class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase-[url(http://pensus.dv.int:8080/assets/img/pensus-check.svg)] ml-7 list text-xl"
      >
        <li>Aktualisierung Ihrer Adresse</li>
        <li>Aktualisierung Ihrer E-Mail-Adresse</li>
        <li>Aktualisierung Ihrer Telefonnummer</li>
        <li>
          Aktualisierung Ihrer Bankverbindung, wenn Sie bereits
          Versorgungsleistungen beziehen.
        </li>
      </ul>
    alt bis -->
      <span class="line"></span>
      <FAQContent />
    </div>
    <FooterMenu />
  </div>
</template>
<style>
.check a {
  @apply relative inline-block after:h-0.5 after:bg-tertiary after:absolute after:w-full after:left-0 after:block  hover:after:h-1 after:transition-all after:duration-500 after:-bottom-0.5;
}
</style>
