<template>
  <div
    class="relative select border-secondary/50"
    v-click-outside="clickedOutside"
  >
    <input
      v-model="searchTerm"
      @input="handleInput"
      @focus="handleFocus"
      @keydown.down.prevent="moveCursor(1)"
      @keydown.up.prevent="moveCursor(-1)"
      @keydown.enter.prevent="handleEnter"
      @keyup.down="scrollDown"
      @keyup.up="scrollUp"
      :placeholder="selectedOption ? '' : placeholder"
      ref="input"
      :tabindex="tabindex"
      :disabled="disabled"
      :class="
        selectedValue == keyEmpty && required != 'false'
          ? 'border-red-500 bg-red-50 text-red-500 ' + inputClass
          : 'border-secondary/50 text-tertiary ' + inputClass
      "
      class="mb-8 p-3 w-full text-black placeholder:text-black"
    />
    <span
      v-if="selected"
      @click.prevent="reseted()"
      class="absolute right-5 top-[18px] flex items-center cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 320 512"
      >
        <path
          d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
        />
      </svg>
    </span>
    <div
      v-show="showOptions"
      @click.self="handleSelf()"
      @focusout="handleSearchEnd"
      :tabindex="tabindex"
      :disabled="disabled"
      :class="dropdownClass"
      ref="dropdown"
    >
      <ul ref="optionsList">
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          @click="handleClick(option)"
          class="p-3 cursor-pointer hover:bg-gray-200"
          :class="{ 'bg-gray-200': index === selectedOptionIndex }"
          ref="optionItem"
        >
          {{ option.text }}
        </li>
        <li v-if="options.length === 0" class="p-3 text-center">
          Keine Suchtreffer
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";

/** Dropdown-Komponente für sämtliche Select-Felder
 * @displayName Dropdown-Feld
 */

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    /**
     * HTML-Attribut für die Darstellung bei fehlendem Inhalt
     * @values false, true
     */
    required: {
      type: String,
      required: false,
      default: "false",
    },
    /**
     * Schlüssel für eine noch nicht getätigte Auswahl
     */
    keyEmpty: {
      type: String,
      required: false,
      default: "-1",
    },
    /**
     * HTML-Attribut
     */
    placeholder: {
      type: String,
      required: false,
      default: "Bitte auswählen",
    },
    /**
     * Liste der zur Verfügung stehenden Optionen
     */
    options: {
      type: Array,
      required: true,
    },
    /**
     * Für das Input-Feld zu ergänzende Klassen
     */
    inputClass: {
      type: String,
      required: false,
      default:
        "border border-gray-300 p-3 rounded-md focus:outline-none focus:shadow-outline",
    },
    /**
     * Für den Dropdown-Container zu ergänzende Klassen
     */
    dropdownClass: {
      type: String,
      required: false,
      default:
        "absolute w-full z-50 bg-white border border-gray-300 -mt-7 max-h-[10rem] overflow-hidden overflow-y-auto rounded-md shadow-md",
    },
    /**
     * Aktuell ausgewählter Wert (für Selfservices aus draft)
     */
    default: {
      type: String,
      required: true,
      default: "-1",
    },
    /**
     * ursprünglich eingestellter Wert (für Selfservices aus olddraft)
     */
    oldDefault: {
      type: String,
      required: false,
      default: "-1",
    },
    /**
     * HTML-Attribut
     */
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    /**
     * HTML-Attribut
     * @values false, true
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showOptions: false,
      selectedOption: null,
      selectedOptionIndex: -1,
      filteredOptions: this.options,
      searchTerm: this.findOption(this.default)["text"], //this.options.find(tab => tab['value'] == this.default)['text'],
      oldDefault2: this.oldDefault == "" ? this.default : this.oldDefault,
      selectedValue: this.default,
    };
  },
  emits: ["input"],
  watch: {
    default(newValue) {
      let _search = this.findOption(this.default)["text"]; //this.options.find(tab => tab['value'] == this.default)['text'];
      this.searchTerm = _search; // == undefined ? this.keyEmpty : _search;
      this.selectedOptionIndex = newValue;
      //this.$servertalk.doLog('watch');
      //this.$servertalk.doLog(newValue);
    },
    searchTerm(newSearchTerm) {
      const matchingOption = this.options.find(
        (option) => option.text.toLowerCase() === newSearchTerm.toLowerCase()
      );
      //this.selected = matchingOption;
      //this.$servertalk.doLog(this.selected);
      if (typeof matchingOption == "object") {
        this.selectedValue = matchingOption.value;
        /**
         * Ausgewählte Option
         * @property {object} matchingOption Ausgewählte Option
         */
        this.$emit("input", matchingOption);
      }
    },
  },

  computed: {
    selected() {
      return (
        this.searchTerm &&
        this.options.some(
          (option) =>
            option.text.toLowerCase() === this.searchTerm.toLowerCase()
        )
      );
    },
  },

  methods: {
    findOption(key) {
      //fange ein leeres Array ab
      if (this.options.length == 0) return { value: "-1", text: "---" };
      //suche den key
      let opt = this.options.find((tab) => tab["value"] == key);
      //wenn nicht gefunden, dann suche den keyEpty
      if (opt == undefined)
        opt = this.options.find((tab) => tab["value"] == this.keyEmpty);
      //wenn auch nicht gefunden - letzter Ausweg - 1 Element
      return opt == undefined ? this.options[0] : opt;
    },
    reseted() {
      this.selectedOptionIndex = this.oldDefault2;
      this.searchTerm = this.findOption(this.oldDefault2)["text"]; //this.options.find(tab => tab['value'] == this.oldDefault2)['text'];

      /*
      // Lösche den Default-Wert und setze searchTerm auf einen leeren String
      this.$refs.input.blur();
      //this.selectedOption.text = this.default.text;
      //this.selectedOption.value = this.default.key;
      this.searchTerm = this.default.text;
      this.showOptions = false;
      //this.selected = this.default;
      // Überprüfe, ob 'value' nicht null ist, bevor auf 'value.text' zugegriffen wird
      this.$servertalk.doLog('xxxxx', this.searchTerm, this.selectedOption);
        this.$emit("input", this.selectedOption);
      */
    },
    reset() {
      //this.selected = this.default;
      //this.reseted();
    },

    handleInput() {
      this.showOptions = true;
      this.selectedOptionIndex = -1;
      // Filter die Optionen basierend auf dem aktuellen Suchbegriff
      this.filteredOptions = this.options.filter((option) =>
        option.text.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },

    handleClick(option) {
      this.selectedOption = option;
      this.searchTerm = option.text;
      this.showOptions = false;
      this.$refs.input.focus();
      this.selectedOptionIndex = -1; // Setze den Index zurück, um die Hervorhebung zu entfernen
      this.handleSearchEnd();
    },

    clickedOutside() {
      this.showOptions = false;
    },

    handleSelf() {
      this.showOptions = true;
    },
    handleFocus() {
      // Zeige alle Optionen an, wenn das Eingabefeld den Fokus erhält
      this.showOptions = true;
    },
    handleSearchEnd() {
      // Beende die Suche und blende die Optionsliste aus wenn ausgewählt
      if (this.selectedOption) {
        this.showOptions = false;
        this.filteredOptions = this.options;
        this.$refs.input.blur();
      }
    },
    moveCursor(step) {
      // Finde den nächsten passenden Index basierend auf dem aktuellen Suchbegriff
      if (this.filteredOptions.length > 0) {
        // Aktualisiere den ausgewählten Index und stelle sicher, dass er im gültigen Bereich liegt
        this.selectedOptionIndex =
          (this.selectedOptionIndex + step + this.filteredOptions.length) %
          this.filteredOptions.length;
        this.searchTerm = this.filteredOptions[this.selectedOptionIndex].text;
      }
    },

    handleEnter() {
      // Wähle das Element aus, wenn Enter gedrückt wird
      if (this.selectedOptionIndex !== -1) {
        this.handleClick(this.filteredOptions[this.selectedOptionIndex]);
      }
    },
    scrollDown() {
      // Scrolle nach unten, um den ausgewählten Wert sichtbar zu machen
      if (this.selectedOptionIndex !== -1) {
        const optionItem = this.$refs.optionItem[this.selectedOptionIndex];
        if (optionItem) {
          optionItem.scrollIntoView(false);
        }
      }
    },

    scrollUp() {
      // Scrolle nach oben, um den ausgewählten Wert sichtbar zu machen
      if (this.selectedOptionIndex !== -1) {
        const optionItem = this.$refs.optionItem[this.selectedOptionIndex];
        if (optionItem) {
          optionItem.scrollIntoView(true);
        }
      }
    },
  },
};
</script>
