<script>
import { store } from "../components/store.vue";

/** Zeigt die im Store hinterlegte Nachricht an unter Berücksichtigung des Status
 * @displayName Alertbox
 */

export default {
  name: "Alertbox",
  data() {
    return {
      store,
    };
  },
};
</script>
<template>
  <div class="alertbox" :class="{ group: store.message.text }">
    <div
      class="fixed translate-y-[999px] group-[.alertbox]:translate-y-0 bottom-8 transition-all duration-1000 left-1/2 -translate-x-1/2 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white z-[70]"
    >
      <div class="mt-3 text-center">
        <div
          v-if="store.message.status === 'ok' || store.message.status === ''"
          class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
        >
          <svg
            class="h-6 w-6 text-green-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </div>
        <div
          v-else
          class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </div>

        <div class="mt-2 px-7 py-3">
          <p class="text-sm font-bold text-tertiary whitespace-pre-line">
            {{ store.message.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
