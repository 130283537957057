<template>
  <div class="fontsize-component gap-2 hidden 2xl:flex">
    <button
      v-for="size in ['A-', 'A', 'A+']"
      :key="size"
      class="font-size-button change-fontsize cursor-pointer border px-2 py-1 text-sm rounded-md w-8 text-center hover:bg-gray-200 hover:text-black transition"
      @click="changeFontSize(size)"
      :disabled="isDisabled(size)"
    >
      {{ size }}
    </button>
  </div>
</template>

<script>
import { store } from "./store.vue";
/** Komponente zum Ändern der Schriftgröße
 * @displayName Fontsize
 */
export default {
  name: "FontSizeComponent",
  methods: {
    changeFontSize(size) {
      if (size === "A-" && store.fontfactor > 1) {
        store.fontfactor -= 0.025;
      } else if (size === "A+" && store.fontfactor < 1.1) {
        store.fontfactor += 0.025;
      } else if (size === "A") {
        store.fontfactor = 1.0;
      }
      this.resize();
    },
    isDisabled(size) {
      return (
        (size === "A-" && store.fontfactor <= 1) ||
        (size === "A+" && store.fontfactor >= 1.1)
      );
    },
    resize() {
      const htmlElement = document.querySelector("html");
      htmlElement.style.fontSize = store.fontfactor + "rem";
    },
  },
};
</script>

<style>
.font-size-button:disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
