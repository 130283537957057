<script>
import { store } from "./store.vue";

/** Button zum Zurücksetzen ungespeicherter Änderungen für Selfservices
 *   @displayName Reset-Button
 */

export default {
  name: "ButtonResetServiceData",
  components: {},
  data() {
    return {
      store,
    };
  },
  methods: {
    resetData(e) {
      // Click-Function auf Button
      if (e) e.preventDefault();
      /**
       * Das Zurücksetzen wurde initiiert und ist durch die rufende Komponente auszuführen.
       */
      this.$emit("reset");
    },
  },
};
</script>
<template>
  <button
    @click="resetData"
    type="submit"
    class="bg-primary text-white py-4 w-64 rounded-md text-base lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary"
    :disabled="store.isReadOnly"
  >
    Zurücksetzen
  </button>
</template>
