<template>
  <router-view v-slot="{ Component, route }">
    <div
      :key="route.name"
      class="flex-grow flex flex-col max-[1920px]:min-h-screen overflow-x-hidden bg-white"
      :class="store.isReadOnly ? 'globalReadonly' : 'globalWritable'"
      :id="currentRouteName"
      >
      <component :is="Component"></component>
    </div>
  </router-view>
</template>
/* eslint-disable */
<script>
import { store } from "./components/store.vue";
import { diffObj, textInTree } from "./utility";

var modTimer = false;

export default {
  name: "App",
  components: {
    // myRouter: router,
    //    LoginPrompt
  },
  data() {
    return {
      store,
      currentRouteName: "", // Die aktuelle Route-Klasse
      isTabClosed: false,
    };
  },
  mounted() {
    /**
     * Start des Systems
     * Eventlisterner für unbeabsichtigtes Neuladen der Seite registrieren
     * Redirect, wenn die Sete nicht mit url/ oder url gestartet wurde
     * 
     *  
     */
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    this.$servertalk.doLog("Route path");
    this.$servertalk.doLog(location.pathname);

    if (!((location.pathname == "/") || (location.pathname == ""))) {
      this.$servertalk.doLog("neustart");
      //this.$route.push('/');
      localStorage.clear();
      window.location.href = "/";
    }

    this.$servertalk.enterSystem();
  },
  beforeUnload() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  methods: {
    handleBeforeUnload(event) {
      if (store.datasent > 0) {
        // Sicherheitsabfrage vor Schliessen des Tabs während der Datenspeicherung
        event.preventDefault();
        event.returnValue = "";
      } else if ((store.datasent == 0) && (location.pathname != "/")) {
        const confirmMessage = "Möchten Sie die Seite wirklich neu laden?";
        event.returnValue = confirmMessage; // Standard für die meisten Browser
        event.preventDefault();
        return confirmMessage; // Für einige ältere Browser erforderlich
      }
      //this.isTabClosed = location.pathname != "/";
    },
    setMeldung(text, status) {
      store.message.text = text;
      store.message.status = status;
    },
  },
  watch: {
    isTabClosed(newValue) {
      // This watch will be triggered when the isTabClosed data property changes
      if (newValue) {
        // Add your code here to handle the beforeunload event
        this.$servertalk.exitSystem();
        this.$servertalk.doLog("User left the page or closed the tab");
      }
    },
    "store.data": {
      handler(newData, oldData) {
        if (typeof oldData.stammdaten !== undefined) {
          const dif = diffObj(store.olddata, store.data);

          //store.datadiff = dif;
          const ctextInTree = textInTree(dif);

          this.$servertalk.doLog("##################");
          this.$servertalk.doLog(ctextInTree);
          this.$servertalk.doLog(dif);
          //nur wenn Texte im Diff stehen, dann gibt es Änderungen
          if (ctextInTree === "") {
            store.datadiff = {};
            this.$servertalk.doLog("empty");
          } else {
            store.datadiff = dif;
            store.modifyhash.data = this.$servertalk.hashString(ctextInTree);
          }
        }
      },
      deep: true,
    },
    "store.draft": {
      handler(newData, oldData) {
        if (typeof oldData.stammdaten !== undefined) {
          const dif = diffObj(store.olddraft, store.draft);
          //store.draftdiff = dif;
          const ctextInTree = textInTree(dif);

          if (ctextInTree === "") {
            store.draftdiff = {};
            this.$servertalk.doLog("empty");
          } else {
            store.draftdiff = dif;
            store.modifyhash.draft = this.$servertalk.hashString(ctextInTree);
          }
        }
      },
      deep: true,
    },
    "store.modifyhash": {
      handler(newData, oldData) {
        this.$servertalk.doLog("########################## Modify");
        //this.$servertalk.doLog(oldData.data + oldData.draft);
        //this.$servertalk.doLog(lastMod);

        //bin in der Inititialisierung, also entweder nicht angemeldet oder 
        //dies ist der 1. Durchlauf 
        let isInit = store.lastMod == "-1" || !store.auth;

        //wer für letzte Änderung speichern
        store.lastMod = oldData.data + oldData.draft;

        //counter für zu übertragende Daten hochzählen
        if (!isInit){
          store.datasent = store.datasent + 1;
        }
        
        //eventuellen Timer löschen
        //also ausstehende Änderungen anhalten
        if (modTimer) {
          clearTimeout(modTimer);
          modTimer = false;
        }


        //dies ist die eigentliche Routine zum senden von Daten
        const mysend = function () {
          this.$servertalk.doLog("sended start");
          this.$servertalk.doLog("sending");
          this.$servertalk.sendDataToDB("", function () {
            /*store.message = {
              text: "Ihre Änderung wurde gespeichert.",
              status: "ok",
            };*/
            //this.$servertalk.doLog("sended end");
            //zu sendende Daten auf 0 setzen
            store.datasent = 0;
          });
          //zu sendende Daten auf 0 setzen
          store.datasent = 0;
        }.bind(this);

        //wenn Daten zu senden sind, dann den Timer starten
        //tue dies nicht wärend der Initialisierung
        if (!isInit && store.datasent > 0) {
          modTimer = setTimeout(mysend, 5000, this);
        } else {
          //sicherheitshalber den Counter wieder auf 0 setzen
          store.datasent = 0;
        }
      },
      deep: true,
    },
    "store.message": {
      // Ausblenden einer gesetzten Meldung nach Zeitintervall
      handler(newMessage, oldMessage) {
        this.$servertalk.doLog(oldMessage);
        if (newMessage.text != "") {
          setTimeout(
            function (setMeldung) {
              setMeldung("", "");
            },
            5000,
            this.setMeldung
          );
        }
      },
      deep: true,
    },
    $route(to, from) {
      // Aktualisiere die currentRouteName, wenn die Route wechselt
      this.currentRouteName = to.name;
    },
  },
};
</script>
