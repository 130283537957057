<script>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
import { isSteuerIdValid } from "validate-steuerid";
/** View-Komponente zum Erfassen der Steuer-ID
 * @displayName Selfservice Erfassung Steuer-ID
 */
export default {
  name: "SelfServiceErfassungSteuerId",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    Alertbox,
    ButtonBack,
  },
  data() {
    return {
      store,
      unversandt: {
        ErfassungSteuerId: false,
      },
      validSteuerid: false,
      steueridInput: "",
      saved: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    const validSteuerID = isSteuerIdValid(store.data.Stammdaten.STEUERIDNR);
    this.$servertalk.doLog("Steuer:", validSteuerID);
    if (this.steueridInput === "") {
      this.steueridInput =
        store.draft.sService.Entwuerfe.ErfassungSteuerId.STEUERIDNR; // Hier nehme Ich an, dass die SteuerID im Store gespeichert ist
      this.validiereSteuerid(); // Führt die Validierung und Aktualisierung der SteuerID aus
    }
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.olddraft.sService.Entwuerfe.ErfassungSteuerId;
      delete store.draft.sService.Entwuerfe.ErfassungSteuerId;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("Inhalt store:");
    this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "ErfassungSteuerId",
      '{"STEUERIDNR":"","uuid":"","version":"1.0"}',
      "1.0"
    );
    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft = store.olddraft.sService.Entwuerfe.ErfassungSteuerId;
      olddraft.STEUERIDNR = store.data.Stammdaten.STEUERIDNR;
      olddraft.uuid = this.$servertalk.getUUID();
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService("ErfassungSteuerId");
    }
  },
  methods: {
    validiereSteuerid() {
      this.validSteuerid = isSteuerIdValid(
        store.draft.sService.Entwuerfe.ErfassungSteuerId.STEUERIDNR
      );
    },
    sendDataFinished() {
      let messageCode =
        this.$servertalk.sendDataSelfService("ErfassungSteuerId");
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Erfassung/Änderung derSteuerID wurde erfolgreich beauftragt.",
            status: "ok",
          }
          : {
            text:
              "Beim Speichern des Auftrags zur Erfassung/Änderung Ihrer Steuer-ID trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      if (messageCode == 0) this.saved = true;
      this.$matomo?.trackEvent("SendService", "ErfassungSteuerId");
      this.$router.push("/auftrag-an-pensus");
    },
    resetData() {
      let messageCode =
        this.$servertalk.copyToDraftSelfService("ErfassungSteuerId");
      store.message =
        messageCode == 0
          ? { text: 'Das Feld "SteuerID" wurde zurückgesetzt.', status: "ok" }
          : {
            text:
              'Beim Zurücksetzen von Feld "SteuerID" trat ein Fehler auf (Error-Code ' +
              messageCode +
              ").",
            status: "error",
          };
    },
  },
  computed: {
    setStatusClass() {
      // Erhalte den Wert "ErfassungSteuerId" von $servertalk
      const erfassungSteuerIdValue = this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.ErfassungSteuerId"
      );
      return erfassungSteuerIdValue;
    },
    setStatusClassSTEUERIDNR() {
      // Erhalte den Wert "STEUERIDNR" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.ErfassungSteuerId.STEUERIDNR"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1 class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Ich möchte die Erfassung/Änderung meiner Steuer-ID melden
          </h1>
          <!--modal content-->
          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span class="bearbeitung bg-yellow-500 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <form :class="setStatusClass">
            <div class="grid xl:grid-cols-1 sm:gap-5 mb-4">
              <div class="relative">
                <label for="erfSteuerId" class="text-sm mb-2 block">
                  Steuerliche Identifikationsnummer
                </label>
                <input maxlength="255" type="text" id="erfSteuerId"
                  class="w-full p-3 border rounded-md mb-8 invalid:border-red-500 border-secondary/50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="{
    'border-yellow-400 !focus:border-yellow-400 bg-yellow-50':
      setStatusClassSTEUERIDNR,
    'focus:border-tertiary/50': !setStatusClassSTEUERIDNR,
  }" v-model="store.draft.sService.Entwuerfe.ErfassungSteuerId.STEUERIDNR
    " inputmode="numeric" @input="validiereSteuerid" :pattern="validSteuerid ? '.*' : 'invalid'" />
                <span class="text-red-500 text-sm absolute left-0 bottom-2" v-if="!validSteuerid">
                  Bitte geben Sie eine gültige Steuer-ID ein.
                </span>
              </div>
            </div>
            <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
              <ButtonSendDataSelfService :disabled="setStatusClass !== 'modified' || !validSteuerid"
                @finished="sendDataFinished" />
              <ButtonResetServiceData :disabled="setStatusClass !== 'modified'" @reset="resetData" />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
