<script>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import DateInput from "../../components/DateInput.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import DokumenteHochladen from "../../components/DokumenteHochladenService.vue";
import Alertbox from "../../components/Alertbox.vue";
import { store } from "../../components/store.vue";
import { getNamePattern } from "../../utility";
/** View-Komponente zum Erfassen eines Antrags auf Vorausberechung
 * @displayName Selfservice Antrag Krankenkassenaenderung
 */
let uuid = 0;
export default {

  name: "SelfServiceAntragKrankenkassenaenderung",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    DateInput,
    ButtonBack,
    CustomSelect,
    DokumenteHochladen,
    Alertbox,

    //VersorgungSelect
  },
  data() {
    return {
      openIndex: null,
      store,
      versorgung: {},
      namePatternSource: getNamePattern().source,
      sBeitragspflicht: {
        text: this.$servertalk.getName(
          "KK_STATUS",
          store.draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG
        ),
        key: store.draft.sService.Entwuerfe.AenderungKrankenkasse
          .BEITRAGSPFLICHTIG,
      },
      sKiloOptions: this.$servertalk.getSelectOpts("KILO"),
      sKilo: {
        text: this.$servertalk.getName(
          "KILO",
          store.draft.sService.Entwuerfe.AenderungKrankenkasse.KILO
        ),
        key: store.draft.sService.Entwuerfe.AenderungKrankenkasse.KILO,
      },
      sKrankenkasse: {
        text: this.$servertalk.getName(
          "KRANKENKASSE",
          store.draft.sService.Entwuerfe.AenderungKrankenkasse.KRANKENKASSE
        ),
        key: store.draft.sService.Entwuerfe.AenderungKrankenkasse.KRANKENKASSE,
      },
      saved: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.olddraft.sService.Entwuerfe.AenderungKrankenkasse;
      delete store.draft.sService.Entwuerfe.AenderungKrankenkasse;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    let action = this.$servertalk.initSelfService(
      "AenderungKrankenkasse",
      '{"abDatum":"","KRANKENKASSE":"-1","BEITRAGSPFLICHTIG":"-1","uploadskk":[],"KILO":"0","KINDER":[],"BEMERKUNGEN":"","uuid":"","uploadsDokumente":[],"NAME":"","VORNAME":"","version":"1.1"}',
      "1.1"
    );
    let _versorgung = false;
    let _kkData = false;
    let _kinderData = false;


    //es kann auch keine KK geben

    if (typeof store.data.Versorgung === "object") {
      if (store.data.Versorgung.PMNR.length > 1) {
        _versorgung = store.data.Versorgung.PMNR[0]
      }
      else {
        _versorgung = store.data.Versorgung.PMNR
      }
    }


    _kkData = _versorgung?.Krankenkasse ?? false;
    _kinderData = _versorgung?.Kinder ?? false;

    this.versorgung = _versorgung;

    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft = store.olddraft.sService.Entwuerfe.AenderungKrankenkasse;



      //olddraft.KRANKENKASSE = "-1";
      olddraft.KRANKENKASSE = _kkData.KKNR;

      if (_kinderData) {
        olddraft.KILO = "1";
        _kinderData?.KLFDNR.forEach(kind => {
          olddraft.KINDER.push(
            {
              VORNAME: kind.KVORNAME,
              NAME: kind.KNAME,
              GEBDAT: kind.KGEBDAT,
              uploads: [], //füllen, da der Service ansonsten nicht angenommen wird
              uploadFolgt: "1",
              vonPensus: true,
              uuid: this.$servertalk.getUUID(),
            }

          )
        })
      }

      olddraft.BEITRAGSPFLICHTIG = _kkData.KKSTATUS; // Dropdown (eigenes)


      olddraft.uuid = this.$servertalk.getUUID();
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService("AenderungKrankenkasse");
    }
  },
  computed: {

    sKrankenkasseOptions() {
      let opts = this.$servertalk.getSelectOpts("KRANKENKASSE");
      //finde die private Kasse
      let privNr = opts.findIndex(el => el.value == '999');

      if (store.draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG == '-1' || store.draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG == '') {
        opts = [{ text: "unbekannt", value: "-1" }, opts[privNr]].concat(opts); // unbekannt
        }
        if (store.draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG != '2') {
        opts = opts.slice(0, privNr - 1).concat(opts.slice(privNr + 1)); // pflichtig oder freiwillig
        }
        if (store.draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG == '2') {
        opts = [opts[privNr]]; // privat
      }
      return opts;
    },

    sBeitragspflichtOptions() {
      let opts = this.$servertalk.getSelectOpts("KK_STATUS");
      if (store.olddraft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG == '-1' || store.olddraft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG == '') {
        opts = [{ text: "unbekannt", value: "-1" }].concat(opts);
      }
      return opts;
    },
    setStatusClass() {
      // Erhalte den Wert "AenderungKrankenkasse" von $servertalk
      const AenderungKrankenkasse = this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse"
      );
      return AenderungKrankenkasse;
    },
    setStatusClassAbDatum() {
      // Erhalte den Wert "abDatum" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse.abDatum"
      );
    },
    setStatusClassKRANKENKASSE() {
      // Erhalte den Wert "KRANKENKASSE" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse.KRANKENKASSE"
      );
    },
    setStatusClassBEITRAGSPFLICHTIG() {
      // Erhalte den Wert "BEITRAGSPFLICHTIG" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG"
      );
    },
    setStatusClassKILO() {
      // Erhalte den Wert "KILO" von $servertalk
      return this.$servertalk.gettAttr(
        "draft.sService.Entwuerfe.AenderungKrankenkasse.KILO"
      );
    },
    validKINDER() {
      let valid = true;
      const pattern = getNamePattern();
      let kinder = store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER;
      const keys = Object.keys(kinder);
      keys.forEach((key, index) => {
        if (
          !kinder[key]["NAME"].match(pattern) ||
          kinder[key]["NAME"] === "" ||
          !kinder[key]["VORNAME"].match(pattern) ||
          kinder[key]["VORNAME"] === "" ||
          isNaN(Date.parse(kinder[key]["GEBDAT"])) ||
          (kinder[key]["uploads"].length == 0 &&
            kinder[key]["uploadFolgt"] !== "1")
        ) {
          valid = false;
        }
      });
      return valid;
    },
    checkKilo() {
      if (store.draft.sService.Entwuerfe.AenderungKrankenkasse.KILO !== "1")
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER = [];
      return store.draft.sService.Entwuerfe.AenderungKrankenkasse.KILO === "1"; // Kinder habend
    },
    checkBeitragspflicht() {
      if (
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG ===
        "2"
      ) {
        // "privat"
        this.setKrankenkasse({ value: "999" }); // KK Privat / Ausland
        this.setKilo({ value: "0" }); // unbekannt
        return true;
      }
      return false;
    },

  },
  methods: {
    toggleAccordion(index) {
      if (this.isOpen(index)) {
        this.openIndex = null;
      } else {
        this.openIndex = index;
      }
    },
    isOpen(index) {
      return this.openIndex === index;
    },


    addKind(event) {
      if (event) {
        event.preventDefault(); // Verhindert das Standardverhalten des Buttons
      }

      if (store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER) {
        const newKind = {
          VORNAME: "",
          NAME: "",
          GEBDAT: "",
          uploads: [], //für die Geburtsurkunde
          uploadFolgt: "0",
          uuid: this.$servertalk.getUUID(),
        };
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER.push(newKind);
        this.$servertalk.doLog("Kind angelegt");
        this.$servertalk.doLog(
          store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER
        );
        this.openIndex =
          store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER.length - 1;
      }
    },
    removeKind(index) {
      if (
        Object.keys(store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER)
          .length == 1
      ) {
        store.message = {
          text: "Wenn Sie keine Kinder erfassen möchten, wählen Sie den entsprechenden Eintrag im Dropdown-Menü.",
          status: "error",
        };
        const newKind = {
          VORNAME: "",
          NAME: "",
          GEBDAT: "",
          uploads: [], //für die Geburtsurkunde
          uploadFolgt: "0",
          uuid: this.$servertalk.getUUID(),
        };
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER.push(newKind);
      } //else {
      //nur files tragen das Element "hmac"
      //damit kann ich Files vom Server löschen
      this.$servertalk.doLog(
        "xxxx",
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER,
        index
      );
      let files = this.$servertalk.treeToArray(
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER[index]
          .uploads,
        "hmac"
      );
      files.forEach((hmac) => {
        this.$servertalk.doLog("Kind gelöscht " + hmac);
        this.$servertalk.delDoc(hmac);
      });
      store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER.splice(
        index,
        1
      );
      store.message = {
        text: "Die Daten zum Kind wurden gelöscht.",
        status: "ok",
      };
      //}
    },
    setKilo(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.KILO =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
        if (val.value + "" === "1")
          // kinderhabend
          this.addKind(null);
      }
    },
    setKrankenkasse(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.KRANKENKASSE =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    setBeitragspflicht(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AenderungKrankenkasse"
      );
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Daten im Bereich\n Antrag Krankenkasse \n wurden erfolgreich erfasst.",
            status: "ok",
          }
          : {
            text:
              "Beim Speichern des Auftrags im Bereich\n Antrag Krankenkasse \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      if (messageCode == 0) this.saved = true;
      this.$matomo?.trackEvent("SendService", "AenderungKrankenkasse");
      this.$router.push("/auftrag-an-pensus");
    },
    resetData() {
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AenderungKrankenkasse"
      );

      store.message =
        messageCode == 0
          ? {
            text: "Ihre Daten im Bereich\n Antrag Krankenkasse \n wurden zurückgesetzt.",
            status: "ok",
          }
          : {
            text:
              "Beim Zurücksetzen der Daten im Bereich\n Antrag Krankenkasse \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
    },
    infoDocStatus(val) {
      this.$servertalk.doLog("infoDocStatus: ");
      this.$servertalk.doLog(val);
    },
    infoDocOk(val) {
      this.$servertalk.doLog("infoDocOk: " + val.uploadStatus);
    },
    docDocStatus(val) {
      this.$servertalk.doLog("docDocStatus: " + val.uploadStatus);
    },
    docKindOk(val, i) {
      if (val == false)
        // Dokument wurde gelöscht: Auch Referenz im Store entfernen
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER[i].uploads =
          [];
    },
    docExtraOk(val, i) {
      if (val == false)
        // Dokument wurde gelöscht: Auch Referenz im Store entfernen
        store.draft.sService.Entwuerfe.AenderungKrankenkasse.uploadsDokumente =
          [];
    },
    newfiles(val, i) {
      this.$servertalk.doLog(i);
      store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER[i].uploads =
        val;
      //store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER[i].uploadFolgt = '0';
    },
    extraFiles(val, i) {
      this.$servertalk.doLog("extra");
      store.draft.sService.Entwuerfe.AenderungKrankenkasse.uploadsDokumente = val;
      //store.draft.sService.Entwuerfe.AenderungKrankenkasse.KINDER[i].uploadFolgt = '0';
    },
    kindUploaded(kind) {
      return `${kind.uploads.length}` > 0;
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1 class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Ich möchte einen Antrag auf Änderung meiner Krankenkassendaten stellen
          </h1>
          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <div class="status-label mb-8" v-if="this.$servertalk.checkAnwaerter()">
            <span class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Optionale Datenerfassung.
            </span>
            <span class="infotext text-tertiary text-sm block">
              Pensus benötigt Ihre Krankenkassendaten erst für Ihren Betriebsrentenantrag. Wenn Sie mögen, dann können Sie diese Daten bereits jetzt angeben.
            </span>
          </div>
          <form id="custom-form" :class="setStatusClass">

            <div class="grid xl:grid-cols-3 gap-5 mb-5">
              <div>
                <label for="aendKrankenkasseAb" class="text-sm mb-2 block">
                  Die Änderung ist gültig ab:
                </label>
                <DateInput :statusClass="setStatusClassAbDatum" name="aendKrankenkasseAb" placeholder="gültiges Datum"
                  :required="true" :disabled="false" dmax="+365" dmin="-0" v-model="store.draft.sService.Entwuerfe.AenderungKrankenkasse.abDatum
    " />
              </div>

            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendBeitragspflicht" class="text-sm mb-2 block">
                  Beitragspflicht
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setBeitragspflicht" class="select" :class="setStatusClassBEITRAGSPFLICHTIG"
                  :keyEmpty="-1" required :options="sBeitragspflichtOptions"
                  :default="store.draft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG"
                  :oldDefault="store.olddraft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG" :tabindex="17"
                  :disabled="!(store.olddraft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG == '-1' || store.olddraft.sService.Entwuerfe.AenderungKrankenkasse.BEITRAGSPFLICHTIG == '')" />
              </div>

              <div v-if="checkBeitragspflicht">
                <label class="text-sm mb-2 block">
                  Krankenversicherungsbescheinigung *
                </label>
                <DokumenteHochladen :formId="store.draft.sService.Entwuerfe.AenderungKrankenkasse.uuid
    " :defaultData="store.draft.sService.Entwuerfe.AenderungKrankenkasse.uploadskk
    " maxfiles="1" minfiles="1" infoText="Krankenversicherungsbescheinigung" docArt="KKBesch" @status="docDocStatus"
                  @isOk="docExtraOk" @newfiles="extraFiles" />
              </div>

              <div v-if="!checkBeitragspflicht">
                <label for="aendKrankenkasse" class="text-sm mb-2 block">
                  Krankenkasse
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setKrankenkasse" class="select" :class="setStatusClassKRANKENKASSE" required
                  :options="sKrankenkasseOptions" :keyEmpty="'-1'" :default="store.draft.sService.Entwuerfe.AenderungKrankenkasse
    .KRANKENKASSE" :oldDefault="store.olddraft.sService.Entwuerfe.AenderungKrankenkasse.KRANKENKASSE" :tabindex="15"
                  :disabled="checkBeitragspflicht" />
              </div>
            </div>

            <div class="grid" v-if="!checkBeitragspflicht">
              <div>
                <label for="aendKilo" class="text-sm mb-2 block">
                  Kinder
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setKilo" class="select" :class="setStatusClassKILO" :keyEmpty="store.draft.sService.Entwuerfe.AenderungKrankenkasse
    .BEITRAGSPFLICHTIG == 2
    ? -1
    : 0
    " required :options="sKiloOptions" :default="store.draft.sService.Entwuerfe.AenderungKrankenkasse.KILO
    " :oldDefault="store.olddraft.sService.Entwuerfe.AenderungKrankenkasse.KILO
    " :tabindex="18" />
              </div>
              <div class="grid" v-if="checkKilo">
                <div v-for="(kind, index) in store.draft.sService.Entwuerfe
    .AenderungKrankenkasse.KINDER" :key="index" class="relative">
                  <div @click="toggleAccordion(index)"
                    class="font-semibold text-lg lg:text-xl trigger py-4 pl-4 pr-8 sm:px-4 first-of-type:bg-gray-100 rounded-md cursor-pointer text-primary relative hover:bg-gray-200 text-left sm:text-center transition-[max-height] flex hyphens-manual items-center justify-between space-x-3 w-full mb-2 duration-500"
                    :aria-expanded="isOpen(index)" :aria-controls="`collapse${index}`" :class="{
    '!mb-0 rounded-b-none bg-gray-200': isOpen(index),
  }">
                    <h2 class="text-lg font-bold text-tertiary">
                      <span v-if="kind.VORNAME && kind.NAME">
                        {{ kind.VORNAME + " " + kind.NAME }}
                      </span>
                      <span v-else>Kind {{ index + 1 }}</span>
                    </h2>

                    <div
                      class="toggle relative before:h-1 after:transition-all after:duration-500 before:w-6 before:-right-4 before:bg-primary before:absolute -top-0.5 sm:before:right-0 after:h-1 after:w-6 after:bg-primary after:absolute after:-right-4 sm:after:right-0 after:-rotate-90 before:rounded after:rounded">
                    </div>
                  </div>
                  <div :id="`collapse${index}`"
                    class="toggle max-h-0 opacity-0 transition-all duration-500 overflow-hidden [&button]:bg-white px-8"
                    :class="{
    'max-h-[2000px] !opacity-100 rounded-b-md mb-2 p-8 bg-gray-50':
      isOpen(index),
  }">
                    <div class="kind grid grid-cols-2 gap-8">
                      <div class="relative" :class="{ 'modified': kind.VORNAME }">
                        <label :for="'aendVorname' + index" class="text-sm mb-2 block">
                          Vorname
                          <sup>*</sup>
                        </label>
                        <input type="text" maxlength="100" :id="'aendVorname' + index" v-model="kind.VORNAME" required
                          class="peer w-full p-3 border border-secondary/50 rounded-md invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                          :pattern="namePatternSource" :disabled="kind?.vonPensus" />
                        <span
                          class="invisible peer-invalid:visible text-red-500 text-sm absolute -bottom-6 left-0 min-w-max">
                          Geben Sie einen gültigen Vornamen ein.
                        </span>
                      </div>
                      <div class="relative" :class="{ 'modified': kind.NAME }">
                        <label :for="'aendName' + index" class="text-sm mb-2 block">
                          Name
                          <sup>*</sup>
                        </label>
                        <input type="text" maxlength="100" :id="'aendName' + index" v-model="kind.NAME" required
                          class="peer w-full p-3 border border-secondary/50 rounded-md invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                          :pattern="namePatternSource" :disabled="kind?.vonPensus" />
                        <span
                          class="invisible peer-invalid:visible text-red-500 text-sm absolute -bottom-6 left-0 min-w-max">
                          Geben Sie einen gültigen Namen ein.
                        </span>
                      </div>
                      <div>
                        <label :for="'aendGebdat' + index" class="text-sm mb-2 block">
                          Geburtsdatum
                          <sup>*</sup>
                        </label>
                        <DateInput :name="'aendGebdat' + index" placeholder="gültiges Datum" :required="true"
                          :disabled="kind?.vonPensus" dmin="-36500" dmax="-0" v-model="kind.GEBDAT"
                          :class="{ 'modified': kind.GEBDAT }" />
                      </div>
                    </div>
                    <div v-if="kind.uploadFolgt == '0'">
                      <label class="text-sm mb-2 block mt-4">
                        Geburtsurkunde von
                        <span v-if="kind.VORNAME && kind.NAME">
                          {{ kind.VORNAME + " " + kind.NAME }}
                        </span>
                        <span v-else>Kind {{ index + 1 }}</span>
                      </label>
                      <DokumenteHochladen :formId="store.draft.sService.Entwuerfe.AenderungKrankenkasse
    .uuid
    " :defaultData="kind.uploads" maxfiles="1" :minfiles="kind.uploadFolgt == '1' ? 0 : 1"
                        infoText="aus dem Self Service Krankenkasse" :docArt="'Geburtsurkunde_' + index" :lfn="index"
                        @status="docDocStatus" @isOk="docKindOk" @newfiles="newfiles" />
                    </div>
                    <input type="hidden" :id="'uuid' + index" name="uuid" value="this.$servertalk.getUUID()" />
                    <div class="flex items-center gap-2" v-if="!kindUploaded(kind)"
                      :class="kind?.vonPensus ? 'hidden' : ''">
                      <input type="checkbox" :id="'aendUploadFolgt' + index" v-model="kind.uploadFolgt" true-value="1"
                        false-value="0"
                        class="border border-secondary/50 rounded-md invalid:border-red-500 focus:outline-0 w-5 h-5 text-tertiary appearance-none bg-white cursor-pointer"
                        :disabled="kindUploaded(kind)" />
                      <label :for="'aendUploadFolgt' + index" class="text-base cursor-pointer">
                        Das Dokument wird nachgereicht
                      </label>
                    </div>
                    <div class="inline-flex justify-between w-full" v-if="!kind?.vonPensus">
                      <input :id="'buttonRemoveKind' + index" type="button" value="Kind löschen"
                        class="bg-primary text-white py-4 w-64 rounded-md mt-8 text-base lg:text-xl hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary cursor-pointer mx-auto"
                        @click="removeKind(index)" />
                    </div>
                  </div>
                </div>
                <input id="buttonAddKind" type="button" value="Kind hinzufügen"
                  class="bg-primary text-white py-4 w-64 rounded-md text-base lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary cursor-pointer my-8"
                  @click="addKind()" />
              </div>
            </div>

            <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0 mt-4">
              <ButtonSendDataSelfService :disabled="setStatusClass !== 'modified' || !validKINDER
    " @finished="sendDataFinished" />
              <ButtonResetServiceData :disabled="setStatusClass !== 'modified'" @reset="resetData" />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
