<script setup>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DragAndDrop from "../components/DragAndDrop.vue";
import SingleFile from "../components/SingleFile.vue";
import CameraCapture from "../components/CameraCapture.vue";
</script>
<script>
import { store } from "../components/store.vue";

export default {
  name: "DokumenteHochladen",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DragAndDrop,
    SingleFile,
  },
  data() {
    return {
      store,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    upLoadFiles: function (f) {
      this.$servertalk.doLog(f);
    },
  },
};
</script>
<template>
  <div class="main-wrapper flex-grow" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 max-w-5xl mx-auto px-5 lg:px-0 mb-0">
      <h1
        class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase"
      >
        Meine Dokumente hochladen
      </h1>
      <h2
        class="text-gray-700 mb-0 text-2xl font-bold pb-2 border-b border-primary"
      >
        Dokumente hochladen
      </h2>
      <div
        class="lg:w-screen lg:-translate-x-1/2 lg:relative lg:left-1/2 bg-gray-50 min-[1921px]:max-w-[1600px] max-w-[1920px]"
      >
        <div
          class="flex flex-col justify-center lg:justify-start lg:flex-row py-16 max-w-5xl mx-auto gap-16 px-5"
        >
          <SingleFile />
          <div class="hidden lg:block">
            <DragAndDrop @change="upLoadFiles(f)" />
          </div>
          <div class="lg:hidden block"><CameraCapture /></div>
        </div>
      </div>
    </div>
  </div>
  <FooterMenu />
</template>
