<script>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import AenderungsauftraegeSelfService from "../components/AenderungsauftraegeSelfService.vue";
import Alertbox from "../components/Alertbox.vue";
import { store } from "../components/store.vue";
/** View-Komponente zur Anzeige verfügbarer Selfservices sowie nicht abgeholter Änderungsaufträge
 * @displayName Seite Auftragsübersicht Selfservices
 */
export default {
  name: "AuftragPensus",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    AenderungsauftraegeSelfService,
    Alertbox,
  },
  data() {
    return {
      inBearbeitung: {
        AenderungPersoenlichedaten: false,
        AenderungBankverbindung: false,
        ErfassungSteuerId: false,
        AenderungKontaktdaten: false,
        AntragBetriebsrente: false,
        AenderungKrankenkasse: false,
        KontaktPensus: false,
      },
      unversandt: {
        AenderungPersoenlichedaten: false,
        AenderungBankverbindung: false,
        ErfassungSteuerId: false,
        AenderungKontaktdaten: false,
        AntragBetriebsrente: false,
        AenderungKrankenkasse: false,
        KontaktPensus: false,
      },
      store,
    };
  },
  computed: {
    isWartung() {
      let wartung = false;
      let omessage = store.config?.messages?.DBWartung ?? false;
      let now = new Date();
      if (omessage && Date.parse(omessage.von) < now && Date.parse(omessage.bis) > now) {
        wartung = true;
      }
      return wartung;
    },
    messageWartung() {
      let message = '';
      if (this.isWartung) {
        message = store.config?.messages?.DBWartung?.message ?? '';
      }

      return message;
    }

  },
  methods: {
    getServices(store) {
      //let filtered = JSON.stringify(store.selfService); // Objekt kopieren
      let filtered = {};
      //filtered = JSON.parse(filtered);
      const keys = Object.keys(store.selfService);
      keys.forEach((key) => {
        let e = store.selfService[key];
        if (
          // nötige Ausschlusskriterien für Services
          e.image.length > 0 && //es muss ein Bild da sein
          (e.conditionHide.indexOf("goLive") < 0 || !store.goLive) && //golive darf nicht gefordert sein ODER wir sind NICHT goLive
          (e.conditionHide.indexOf("notVerbotFirma") < 0 ||
            this.$servertalk.checkVerbotFirma()) &&
          (e.conditionHide.indexOf("notAnwaerter") < 0 ||
            this.$servertalk.checkAnwaerter()) && // Nicht nur für Anwäerter oder er ist Anwärter
          (e.conditionHide.indexOf("notRentner") < 0 ||
            this.$servertalk.checkRentner()) && // Nur für Rentner
          (e.conditionHide.indexOf("Steuernummer") < 0 ||
            store.data.Stammdaten.STEUERIDNR.length <= 0) //keine Steuernummer-Verpflichtung oder er hat keine
        ) {
          filtered[key] = e;
          this.$servertalk.doLog(e.conditionHide.indexOf("notAnwaerter") >= 0);
          this.$servertalk.doLog(key);
        }
      });

      this.$servertalk.doLog(filtered);
      /*
      const keys = Object.keys(filtered);
      this.$servertalk.doLog(keys);
      keys.forEach((key, index) => {
        if (
          filtered[key].image.length == 0 || // Ausschlusskriterien für Services
          (filtered[key].conditionHide.indexOf("goLive") >= 0 &&
            store.goLive) ||
          (filtered[key].conditionHide.indexOf("notAnwaerter") >= 0 &&
            this.$servertalk.checkAnwaerter()) ||
          (filtered[key].conditionHide.indexOf("Steuernummer") >= 0 &&
            store.data.Stammdaten.STEUERIDNR.length > 0)
        )
          delete filtered[key];
      });
      */
      return filtered;
    },
    getInBearbeitung(index) {
      return this.inBearbeitung[index];
    },
    getUnversandt(index) {
      return this.unversandt[index];
    },
    chckUnbearbeitet() {
      for (const [key] of Object.entries(this.inBearbeitung)) {
        this.inBearbeitung[key] =
          this.$servertalk.gettAttr("draft.sService.Entwuerfe." + key) ==
          "modified";
        this.unversandt[key] = this.$servertalk.checkForUnsentSelfService(key);
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$servertalk.doLog("Inhalt Store:", store);
    this.chckUnbearbeitet();
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
};
</script>
<template>
  <div class="main-wrapper flex-grow" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 mb-32 max-w-7xl mx-auto px-5 2xl:px-0">
      <h1 class="text-primary/75 font-bold text-2xl lg:text-4xl text-center mb-8 sm:mb-16 uppercase">
        Ihr Auftrag an uns
      </h1>
      <Alertbox />
      <div v-if="isWartung"
        class="p-5 text-primary border border-primary bg-primary/10 rounded-md my-5 max-w-3xl mx-auto">{{ messageWartung }}</div>
      <div class="unversandt">
        <AenderungsauftraegeSelfService @removed="chckUnbearbeitet" />
      </div>
      <div
        class="grid gap-5 md:grid-cols-2 xl:grid-cols-3 [&>.item>a>.item-text]:uppercase [&>.item>a>.item-text]:font-bold [&>.item>a>.item-text]:text-center [&>.item>a>.item-text]:px-2 [&>.item>a>.item-text]:text-white [&>.item>a>.item-text]:transition-all [&>.item>a>.item-text]:duration-500 [&>.item>a>.item-text]:py-2 [&>.item>a>.item-image]:border-t [&>.item>a>.item-image]:border-primary">
        <div v-for="(service, index) in getServices(store)" :key="index" class="item group">
          <router-link active-class="font-bold" :to="service.url" class="block">
            <div class="item-image relative">
              <img :src="service.image" />
              <div v-if="getInBearbeitung(index)" class="status-label absolute top-0 right-0">
                <span class="bearbeitung bg-yellow-500 text-tertiary block py-1 px-2 text-sm uppercase">
                  Entwurf
                </span>
              </div>
              <div v-if="getUnversandt(index)" class="status-label absolute top-0 left-0">
                <span class="unversandt bg-green-500 text-white block py-1 px-2 text-sm uppercase">
                  In Auftrag
                </span>
              </div>
            </div>
            <div class="item-text bg-tertiary/80 group-hover:bg-tertiary">
              {{ service.title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <FooterMenu />
</template>
