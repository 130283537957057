<template>
  <PasswortVergessen />
</template>

<script>
import PasswortVergessen from "../components/PasswortVergessenComponent.vue";
/** View-Komponente zur Anforderung eines neuen Passwortes
 * @displayName Seite Passwort vergessen
 */
export default {
  name: "ForgotPasswordPage",
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  components: {
    // myRouter: router,
    PasswortVergessen,
  },
};
</script>
