<template>
  <div class="relative z-0 b-2 w-full group">
    <fieldset v-if="localDateFormat == '20#12#2020'" class="group">
      <legend
        class="text-xl font-light text-secondary scale-75 top-0 -z-10 origin-[0] peer-focus:left-0 group-focus-within:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 mb-1">
        Geburtsdatum (Tag/Monat/Jahr)
      </legend>
      <div
        class="pb-2 flex gap-2 [&>*]:bg-slate-100 [&>*]:p-2 [&>*]:rounded-md [&>*]:outline-0 border-b-2 focus-within:border-b-primary border-b-secondary/50 [&>*]:text-xl">
        <input
          class="focus:bg-slate-200"
          ref="dayInput"
          :class="[fieldsCustomClasses, dayErrorClasses]"
          :data-purpose="`${dataPurposeProp}_day_field`"
          :value="dValue01"
          inputmode="numeric"
          name="day"
          max="31"
          min="0"
          pattern="[0-9]*"
          placeholder="TT"
          type="number"
          maxlength="2"
          size="3"
          @focus="focused('dValue01')"
          @input="handleDay($event.target.value)"
          @keydown.down.prevent="incrementDay($event.code)"
          @keydown.up.prevent="incrementDay($event.code)"
          @keydown.backspace.prevent="erase('dValue01')"
          @keydown.e.prevent />
        <input
          class="focus:bg-slate-200"
          ref="monthInput"
          :class="[fieldsCustomClasses, monthErrorClasses]"
          :data-purpose="`${dataPurposeProp}_month_field`"
          :value="dValue02"
          inputmode="numeric"
          max="12"
          min="0"
          name="month"
          id="month"
          pattern="[0-9]*"
          placeholder="MM"
          type="number"
          maxlength="2"
          size="4"
          @focus="focused('dValue02')"
          @input="handleMonth($event.target.value)"
          @keydown.down.prevent="incrementMonth($event.code)"
          @keydown.up.prevent="incrementMonth($event.code)"
          @keydown.backspace.prevent="erase('dValue02')"
          @keydown.e.prevent />
        <input
          class="focus:bg-slate-200"
          ref="yearInput"
          :class="[fieldsCustomClasses, yearErrorClasses]"
          :data-purpose="`${dataPurposeProp}_year_field`"
          :max="maxSupportedYear"
          :min="minSupportedYear"
          :value="dValue03"
          name="year"
          id="year"
          inputmode="numeric"
          pattern="[0-9]*"
          placeholder="J J J J"
          type="number"
          maxlength="4"
          size="4"
          @blur="
            $emit('blur');
            dateError = this.computeDateError();
          "
          @input="handleYear($event.target.value)"
          @focus="focused('dValue03')"
          @keydown.down.prevent="incrementYear($event.code)"
          @keydown.up.prevent="incrementYear($event.code)"
          @keydown.backspace.prevent="erase('dValue03')"
          @keydown.e.prevent />
      </div>
    </fieldset>

    <fieldset v-if="localDateFormat == '12#20#2020'" class="group">
      <legend
        class="text-xl font-light text-secondary scale-75 top-0 -z-10 origin-[0] peer-focus:left-0 group-focus-within:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 mb-1 has-[.date-wrapper]:text-primary">
        Geburtsdatum (Monat/Tag/Jahr)
      </legend>
      <div
        class="date-wrapper pb-2 flex gap-2 [&>*]:bg-slate-100 [&>*]:p-2 [&>*]:rounded-md [&>*]:outline-0 border-b-2 border-b-secondary/50 [&>*]:focus:border-slate-200 [&>*]:text-xl">
        <input
          class="focus:bg-slate-200"
          ref="monthInput"
          :class="[fieldsCustomClasses, monthErrorClasses]"
          :data-purpose="`${dataPurposeProp}_month_field`"
          :value="dValue02"
          inputmode="numeric"
          max="12"
          min="0"
          name="month"
          pattern="[0-9]*"
          placeholder="MM"
          type="number"
          maxlength="2"
          size="4"
          @focus="focused('dValue02')"
          @input="handleMonth($event.target.value)"
          @keydown.down.prevent="incrementMonth($event.code)"
          @keydown.up.prevent="incrementMonth($event.code)"
          @keydown.backspace.prevent="erase('dValue02')"
          @keydown.e.prevent />

        <input
          class="focus:bg-slate-200"
          ref="dayInput"
          :class="[fieldsCustomClasses, dayErrorClasses]"
          :data-purpose="`${dataPurposeProp}_day_field`"
          :value="dValue01"
          inputmode="numeric"
          name="day"
          max="31"
          min="0"
          pattern="[0-9]*"
          placeholder="TT"
          type="number"
          maxlength="2"
          size="3"
          @focus="focused('dValue01')"
          @input="handleDay($event.target.value)"
          @keydown.down.prevent="incrementDay($event.code)"
          @keydown.up.prevent="incrementDay($event.code)"
          @keydown.backspace.prevent="erase('dValue01')"
          @keydown.e.prevent />

        <input
          class="focus:bg-slate-200"
          ref="yearInput"
          :class="[fieldsCustomClasses, yearErrorClasses]"
          :data-purpose="`${dataPurposeProp}_year_field`"
          :max="maxSupportedYear"
          :min="minSupportedYear"
          :value="dValue03"
          name="year"
          inputmode="numeric"
          pattern="[0-9]*"
          placeholder="J J J J"
          type="number"
          maxlength="4"
          size="5"
          @blur="
            $emit('blur');
            dateError = this.computeDateError();
          "
          @input="handleYear($event.target.value)"
          @focus="focused('dValue03')"
          @keydown.down.prevent="incrementYear($event.code)"
          @keydown.up.prevent="incrementYear($event.code)"
          @keydown.backspace.prevent="erase('dValue03')"
          @keydown.e.prevent />
      </div>
    </fieldset>

    <fieldset v-if="localDateFormat == '2020#20#12'" class="group">
      <legend
        class="text-xl font-light text-secondary scale-75 top-0 -z-10 origin-[0] peer-focus:left-0 group-focus-within:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 mb-1">
        Geburtsdatum (Jahr/Tag/Monat)
      </legend>
      <div
        class="pb-2 flex gap-2 [&>*]:bg-slate-100 [&>*]:p-2 [&>*]:rounded-md [&>*]:outline-0 border-b-2 border-b-secondary/50 [&>*]:focus:border-slate-200 [&>*]:text-xl">
        <input
          class="focus:bg-slate-200"
          ref="yearInput"
          :class="[fieldsCustomClasses, yearErrorClasses]"
          :data-purpose="`${dataPurposeProp}_year_field`"
          :max="maxSupportedYear"
          :min="minSupportedYear"
          :value="dValue03"
          name="year"
          inputmode="numeric"
          pattern="[0-9]*"
          placeholder="J J J J"
          type="number"
          maxlength="4"
          size="5"
          @blur="
            $emit('blur');
            dateError = this.computeDateError();
          "
          @input="handleYear($event.target.value)"
          @focus="focused('dValue03')"
          @keydown.down.prevent="incrementYear($event.code)"
          @keydown.up.prevent="incrementYear($event.code)"
          @keydown.backspace.prevent="erase('dValue03')"
          @keydown.e.prevent />
        <input
          class="focus:bg-slate-200"
          ref="dayInput"
          :class="[fieldsCustomClasses, dayErrorClasses]"
          :data-purpose="`${dataPurposeProp}_day_field`"
          :value="dValue01"
          inputmode="numeric"
          name="day"
          max="31"
          min="0"
          pattern="[0-9]*"
          placeholder="TT"
          type="number"
          maxlength="2"
          size="3"
          @focus="focused('dValue01')"
          @input="handleDay($event.target.value)"
          @keydown.down.prevent="incrementDay($event.code)"
          @keydown.up.prevent="incrementDay($event.code)"
          @keydown.backspace.prevent="erase('dValue01')"
          @keydown.e.prevent />

        <input
          class="focus:bg-slate-200"
          ref="monthInput"
          :class="[fieldsCustomClasses, monthErrorClasses]"
          :data-purpose="`${dataPurposeProp}_month_field`"
          :value="dValue02"
          inputmode="numeric"
          max="12"
          min="0"
          name="month"
          pattern="[0-9]*"
          placeholder="MM"
          type="number"
          maxlength="2"
          size="4"
          @focus="focused('dValue02')"
          @input="handleMonth($event.target.value)"
          @keydown.down.prevent="incrementMonth($event.code)"
          @keydown.up.prevent="incrementMonth($event.code)"
          @keydown.backspace.prevent="erase('dValue02')"
          @keydown.e.prevent />
      </div>
    </fieldset>
    <fieldset v-if="localDateFormat == '2020#12#20'" class="group">
      <legend
        class="text-xl font-light text-secondary scale-75 top-0 -z-10 origin-[0] peer-focus:left-0 group-focus-within:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 mb-1">
        Geburtsdatum (Jahr/Monat/Tag)
      </legend>
      <div
        class="pb-2 flex gap-2 [&>*]:bg-slate-100 [&>*]:p-2 [&>*]:rounded-md [&>*]:outline-0 border-b-2 border-b-secondary/50 [&>*]:focus:border-slate-200 [&>*]:text-xl">
        <input
          class="focus:bg-slate-200"
          ref="yearInput"
          :class="[fieldsCustomClasses, yearErrorClasses]"
          :data-purpose="`${dataPurposeProp}_year_field`"
          :max="maxSupportedYear"
          :min="minSupportedYear"
          :value="dValue03"
          name="year"
          inputmode="numeric"
          pattern="[0-9]*"
          placeholder="J J J J"
          type="number"
          maxlength="4"
          size="5"
          @blur="
            $emit('blur');
            dateError = this.computeDateError();
          "
          @input="handleYear($event.target.value)"
          @focus="$emit('focus')"
          @keydown.down.prevent="incrementYear($event.code)"
          @keydown.up.prevent="incrementYear($event.code)"
          @keydown.backspace.prevent="erase('dValue03')"
          @keydown.e.prevent />
        <input
          class="focus:bg-slate-200"
          ref="monthInput"
          :class="[fieldsCustomClasses, monthErrorClasses]"
          :data-purpose="`${dataPurposeProp}_month_field`"
          :value="dValue02"
          inputmode="numeric"
          max="12"
          min="0"
          name="month"
          pattern="[0-9]*"
          placeholder="MM"
          type="number"
          maxlength="2"
          size="4"
          @focus="$emit('focus')"
          @input="handleMonth($event.target.value)"
          @keydown.down.prevent="incrementMonth($event.code)"
          @keydown.up.prevent="incrementMonth($event.code)"
          @keydown.backspace.prevent="erase('dValue02')"
          @keydown.e.prevent />

        <input
          class="focus:bg-slate-200"
          ref="dayInput"
          :class="[fieldsCustomClasses, dayErrorClasses]"
          :data-purpose="`${dataPurposeProp}_day_field`"
          :value="dValue01"
          inputmode="numeric"
          name="day"
          max="31"
          min="0"
          pattern="[0-9]*"
          placeholder="TT"
          type="number"
          maxlength="2"
          size="3"
          @focus="$emit('focus')"
          @input="handleDay($event.target.value)"
          @keydown.down.prevent="incrementDay($event.code)"
          @keydown.up.prevent="incrementDay($event.code)"
          @keydown.backspace.prevent="erase('dValue01')"
          @keydown.e.prevent />
      </div>
    </fieldset>
    <p class="text-primary max-[380px]:text-xs text-sm">{{ meldung }}</p>
  </div>
</template>

<script>
import { store } from "./store.vue";
export default {
  name: "CustomDateInput",
  props: {
    fieldsCustomClasses: {
      type: String,
      default: "",
    },
    fieldsErrorClasses: {
      type: String,
      default: "",
    },
    dataPurposeProp: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dayDataPurpose: null,
      dayError: false,
      dValue01: null,
      dateError: false,
      monthDataPurpose: null,
      monthError: false,
      dValue02: null,
      yearDataPurpose: null,
      yearError: false,
      dValue03: null,
      yearKey: 0,
      maxSupportedYear: 2100,
      minSupportedYear: 1900,
      minDayOrdValue02ForLoop: "01",
      minDayOrdValue02ForMarginBound: 0,
      minDayOrdValue02: 1,
      maxdValue01: 31,
      maxdValue02: 12,
      digitsInYear: 4,
      digitsInDayOrMonth: 2,
      maxDayOrMonthLength: 3,
      maxDayNumberWithPossibleSecondDigit: 3,
      maxMonthNumberWithPossibleSecondDigit: 1,
      highestThreeDigitNumber: 999,
      meldung: "",
    };
  },
  computed: {
    localDateFormat() {
      const date = new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738));
      let fString = new Intl.DateTimeFormat().format(date);
      //console.log(fString);
      return fString.replace(/[\W]/gi, "#");
    },
    date() {
      return this.fullDate
        ? `${this.dValue03}-${this.dValue02}-${this.dValue01}`
        : null;
    },
    dayErrorClasses() {
      //console.log(this.dateError);
      return this.dayError || this.dateError
        ? `${this.fieldsErrorClasses} error`
        : "";
    },
    monthErrorClasses() {
      //console.log(this.dateError);
      return this.monthError || this.dateError
        ? `${this.fieldsErrorClasses} error`
        : "";
    },
    yearErrorClasses() {
      //console.log(this.dateError);
      return this.yearError || this.dateError
        ? `${this.fieldsErrorClasses} error`
        : "";
    },
    fullDate() {
      return this.dValue01 && this.dValue02 && this.dValue03;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          const splitDate = this.value.split("-");
          this.dValue01 = splitDate[2];
          this.dValue02 = splitDate[1];
          this.dValue03 = splitDate[0];
        }
      },
    },
    date: {
      immediate: true,
      handler() {
        if (this.date) {
          let err = false;
      let ok = "" !== this.date;
      let d = 0;
      ok =
        ok &&
        /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
          this.date
        );
      if (ok) {
        d = new Date(this.date);
        ok = d instanceof Date;
      }
      if (ok) {
        let dmin = new Date("1899-01-01");
        let dmax = new Date("2023-01-01");
        ok = d > dmin && d < dmax;
      }

      this.$servertalk.doLog(this.date);
      if (!ok) {
        this.meldung = "Bitte geben Sie ein gültiges Datum an";
        err = true;
      } else {
        //if (/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(this.birthday)) {
        this.meldung = "";
        store.birthday = this.date;
        //        } else {
        //          this.meldung = 'Bitte geben Sie ein gültiges Datum an.';
        //          err = 'true';
        //        }
      }
      /**
       * Das eingegebene Geburtsdatum wurde geprüft
       * @property {boolean} err true wenn ungültiges Datum
       */
      this.$emit("birthdateChecked", err);
        }
      },
    },
  },
  methods: {
    focused(field) {
      this[field] = "";
      this.$emit('focus');
    },
    computeDateError() {
      try {
        const date = new Date(this.date);
        return date.getDate() !== parseInt(this.dValue01);
      } catch {
        return true;
      }
    },
    incrementDay(key) {
      let value = this.dValue01;
      key === "ArrowDown" ? value-- : value++;
      this.dValue01 = this.formattedValue(this.loop(value, this.maxdValue01));
    },
    incrementMonth(key) {
      let value = this.dValue02;
      key === "ArrowDown" ? value-- : value++;
      this.dValue02 = this.formattedValue(this.loop(value, this.maxdValue02));
    },
    incrementYear(key) {
      let value = this.dValue03;
      key === "ArrowDown" ? value-- : value++;
      this.dValue03 = this.formattedValue(
        this.loop(value, this.maxSupportedYear, this.minSupportedYear),
        this.digitsInYear
      );
    },
    handleDay(value) {
      this.dayError = false;
      if (value === "") return;

      value = this.marginBound(
        value,
        this.minDayOrdValue02ForMarginBound,
        this.maxdValue01
      );
      const passToNextField =
        parseInt(value) > this.maxDayNumberWithPossibleSecondDigit ||
        value.length === this.maxDayOrMonthLength;

      this.dValue01 = this.formattedValue(value);
      setTimeout(
        () =>
          (this.dayError =
            parseInt(this.dValue01) < this.minDayOrdValue02 ||
            parseInt(this.dValue01) > this.maxdValue01),
        500
      );
      if (passToNextField) this.$refs.monthInput.focus();
    },
    handleMonth(value) {
      this.monthError = false;
      if (value === "") return;

      value = this.marginBound(
        value,
        this.minDayOrdValue02ForMarginBound,
        this.maxdValue02
      );
      const passToNextField =
        parseInt(value) > this.maxMonthNumberWithPossibleSecondDigit ||
        value.length === this.maxDayOrMonthLength;

      this.dValue02 = this.formattedValue(value);
      setTimeout(
        () =>
          (this.monthError =
            parseInt(this.monthError) < this.minDayOrdValue02 ||
            parseInt(this.monthError) > this.maxdValue02),
        500
      );
      if (passToNextField) this.$refs.yearInput.focus();
    },
    handleYear(value) {
      this.dValue03 = value;

      value = this.formattedValue(value, this.digitsInYear);

      this.dValue03 = this.marginBound(value, "0000", "9999");
      this.yearError =
        this.dValue03 > this.highestThreeDigitNumber &&
        (parseInt(this.dValue03) > this.maxSupportedYear ||
          parseInt(this.dValue03) < this.minSupportedYear);
    },
    erase(field) {
      this[field] = "";
    },
    loop(value, max, min = this.minDayOrdValue02ForLoop) {
      if (parseInt(value) > max) return min;
      if (parseInt(value) < min) return max;

      return value;
    },
    marginBound(value, lowerBound, upperBound) {
      if (parseInt(value).toString().length < upperBound.toString().length)
        return value;
      if (parseInt(value) > upperBound) return upperBound;
      if (parseInt(value) < lowerBound) return lowerBound;
      return value;
    },
    formattedValue(value, digits = this.digitsInDayOrMonth) {
      const stringValue = value.toString();
      const difference = stringValue.length - digits;

      if (difference > 0) return stringValue.slice(difference);

      return stringValue.padStart(digits, "0");
    },
  },
};
</script>

<style scoped>
.error {
  @apply bg-primary/10;
}
</style>
