// utility.js

/**
  * Liefert den Regulären Ausdruck zum Prüfen der Postleitzahl
  * @returns {string} Regulärer Ausdruck
*/
export function getPostalCodePattern() {
  //return /\b(?:[0-9]{5}|[0-9]{4}\s?[A-Z]{2})\b/;
  return /.*/;
}
/**
  * Liefert den Regulären Ausdruck zum Prüfen der Telefonnummer
  * @returns {string} Regulärer Ausdruck
*/
export function getPhoneNumberPattern() {
  return /\+?[0-9\/\- ]+/;
}
/**
  * Liefert den Regulären Ausdruck zum Prüfen von Straße und Hausnummer
  * @returns {string} Regulärer Ausdruck
*/
export function getStreetAndNumberPattern() {
  return /.*\S{2}.*[A-Za-z0-9 \-]{2}.*/;
  //return /^\S*([A-Za-z0-9 \-]{2})\S*$/;
  //return /^(([a-zA-ZäöüÄÖÜ]\D*)\s+\d+?\s*.*)$/;
}
/**
  * Liefert den Regulären Ausdruck zum Prüfen eines Namens
  * @returns {string} Regulärer Ausdruck
*/
export function getNamePattern() {
  return /(.*[a-z .]){2}/i;
}

/**
  * Liefert den Regulären Ausdruck zum Prüfen eines Namens
  * @returns {string} Regulärer Ausdruck
*/
export function getNamePatternEmpty() {
  return /(.*[a-z .]*)/i;
}
/**
  * Liefert die Levenhstein-Diszanz (Editierdistanz) zwischen den überbegenen Strings
  * @param {string} a Erste Zeichenkette für den Vergleich
  * @param {string} b Zweite Zeichenkette für den Vergleich
  * @returns {number} Distanz zwischen den Zeichenketten (min. Anzahl von Operation zur Umwandlung von a nach b)
*/
export function getLevenshteinDistance(a, b) {
  if (a.length == 0) return b.length;
  if (b.length == 0) return a.length;

  var matrix = [];

  // increment along the first column of each row
  var i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  var j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) == a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
          Math.min(matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1)); // deletion
      }
    }
  }

  return matrix[b.length][a.length];
}
/**
  * Ergänzt object2 um keys aus Object1, wenn diese fehlen
  * @param {object} obj1 Masterobject
  * @param {object} obj2 zu ergänzendes Object
  * @returns {object} obj2, ergänzt um fehlende Inhalte
*/
export function supplementObj(obj1, obj2) {
  const result = true;
  if (Object.is(obj1, obj2)) {
    return obj2;
  }
  if (!obj2 || typeof obj2 !== "object") {
    return obj1;
  }
  Object.keys(obj1 || {})    
    .forEach((key) => {
      let a = obj1[key];
      let b = obj2[key];
      console.log(key,b)
      //in a enthalen, in b aber nicht
      if (typeof b == "undefined" && typeof a != "undefined") {
        //als kopie erzeugen
        obj2[key] = JSON.parse(JSON.stringify(a));
      }
      //steige bei Bedarf in die Rekursion ein
      if (typeof b === "object" && typeof a === "object") {
        supplementObj(a, b);
      }
    });
  return obj2;
}

export function diffObj(obj1, obj2) {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== "object") {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      let a = typeof obj1[key] === "undefined" ? "" : obj1[key];
      let b = typeof obj2[key] === "undefined" ? "" : obj2[key];
      //if (b !== a && b.length != 0 && !Object.is(a, b)) {
      if (b !== a && !Object.is(a, b) && key !== "Timestamp") {
        result[key] = b === "" ? "#deleted" : b;
      }
      //steige bei Bedarf in die Rekursion ein
      if (typeof b === "object" && typeof a === "object") {
        const value = diffObj(a, b);
        if (value !== undefined && value.length !== 0 && key !== "Timestamp") {
          result[key] = value;
        }
      }
    });
  return result;
}

export function textInTree(obj, s = "") {
  //wenn es ein Object ist
  //und Kinder hat

  let mykeys = Object.keys(obj || {});
  if (typeof obj === "object" && mykeys.length > 0) {
    //Hole die Keys vom Object und gehe durch
    mykeys.forEach((key) => {
      let b = obj[key];
      if (typeof b === "object") {
        //gehe in die Rekursion
        s = textInTree(b, s);
      } else {
        //ansonsten als String anfügen
        if (typeof b !== "undefined") {
          s = s + b.toString().trim();
        } else {
          this.$servertalk.doLog(key);
        }
      }
    });
  } else {
    //wenn es kein Object ist, dann gebe den Text aud
    if (typeof obj !== "object") {
      s = s + obj.toString().trim();
    }
  }
  //String zurückgeben

  return s;
}