<script>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import DateInput from "../../components/DateInput.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
/** View-Komponente für den Antrag auf Teilnahme an Beiratsversammlungen
 * @displayName Selfservice Teilnahme Beiratsversammlungen
 */
export default {
  name: "SelfServiceTeilnahmeBeiratsversammlung",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    DateInput,
    ButtonBack,
    //VersorgungSelect
  },
  data() {
    return {
      store,
      meldung: "",
      gueltigAb:
        store.data.Entwuerfe.Firmenaustritt.gueltigAb == ""
          ? new Date().toISOString().slice(0, 10)
          : store.data.Entwuerfe.Firmenaustritt.gueltigAb,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  beforeCreate: function () {
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("#");
    if (typeof store.data.sService === "undefined") {
      store.data.sService = {};
    }
    if (typeof store.data.Entwuerfe === "undefined") {
      store.data.Entwuerfe = {};
    }
    if (typeof store.data.sService.Firmenaustritte === "undefined") {
      store.data.sService.Firmenaustritte = {};
    }
    if (typeof store.data.Entwuerfe.Firmenaustritt === "undefined") {
      store.data.Entwuerfe.Firmenaustritt = JSON.parse(
        '{"gueltigAb":"","Versicherungsnummer":"","Kennr":"","Abrechnungsgruppe":"","Traegerunternehmen":"","NAME":"","VORNAME":"","BeitragBis":"","Diensteintritt":"","Grund":"","weiterVersicherung":"","EMAIL":"","TELEFON":"","PMR":""}'
      );
      this.$servertalk.doLog("defined");
    }

    store.data.Entwuerfe.Firmenaustritt.PMNR = store.data.Versorgung.PMNR;
    store.data.Entwuerfe.Firmenaustritt.NAME = store.data.Stammdaten.NAME;
    store.data.Entwuerfe.Firmenaustritt.VORNAME = store.data.Stammdaten.VORNAME;
    store.data.Entwuerfe.Firmenaustritt.EMAIL = store.data.Stammdaten.EMAIL;
    store.data.Entwuerfe.Firmenaustritt.TELEFON =
      store.data.Privatadresse.PTELEFON;

    //if (typeof store.data.sService.Firmenaustritt.gueltigAb ==='undefined'){store.data.sService.Firmenaustritt.gueltigAb=0}
  },
  methods: {
    sendDataFinished() {
      this.$servertalk.doLog("SendDummy");
      this.$servertalk.doLog(store.data.Entwuerfe);
      this.$servertalk.doLog(this.gueltigAb);
    },
    getGueltigAb(d) {
      store.data.Entwuerfe.Firmenaustritt.gueltigAb = d;
      this.$servertalk.doLog(d);
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1
            class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Ich möchte die Teilnahme an einer Beiratsversammlung melden
          </h1>
          <div class="status-label mb-8">
            <span
              class="bearbeitung bg-yellow-500 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              In Bearbeitung
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dieses Dokument befindet sich in Bearbeitung, bitte bestätigen Sie
              Ihre Änderungen.
            </span>
          </div>
          <form>
            <label for="Versorgung" class="text-sm mb-2 block">Firma</label>
            <!--<VersorgungSelect
          name="Versorgung"

          /> -->
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="austrittMail" class="text-sm mb-2 block">
                  E-Mail
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="austrittMail"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="store.data.Stammdaten.EMAIL" />
              </div>
              <div>
                <label for="austrittTelefon" class="text-sm mb-2 block">
                  Telefon
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="austrittTelefon"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="store.data.Stammdaten.PTELEFON" />
              </div>
            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="austrittName" class="text-sm mb-2 block">
                  Vorname Versicherungsnehmer
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="vorname"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="store.data.Stammdaten.VORNAME" />
              </div>
              <div>
                <label for="austrittName" class="text-sm mb-2 block">
                  Name Versicherungsnehmer
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="vorname"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="store.data.Stammdaten.NAME" />
              </div>
            </div>
            <label for="gueltigAb" class="text-sm mb-2 block">
              Mein Firmenaustritt ist gültig ab
            </label>
            <DateInput
              name="gueltigAb"
              placeholder="gültiges Datum"
              @date="getGueltigAb"
              :default="gueltigAb" />
            <label for="gueltigAb" class="text-sm mb-2 block">
              Grund für den Firmenaustritt
              <sup>*</sup>
            </label>
            <textarea
              v-model="store.data.Entwuerfe.Firmenaustritt.Grund"
              placeholder="Hier eine kurze Erklärung angeben"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:border-tertiary/50 focus:outline-0 transition-all duration-500"
              required></textarea>

            <ButtonSendDataSelfService @finished="sendDataFinished" />
            <p>{{ meldung }}</p>
          </form>
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
