<script>
import { store } from "./store.vue";

/**
 * Anzeige der Übersicht aller verfügbaren Selfservices sowie einer Liste der Aufträge, die noch nicht von Pensus abgerufen wurden.
 * @displayName Änderungsaufträge für Selfservices
 */
export default {
  name: "AenderungsauftraegeSelfService",
  components: {},
  data() {
    return {
      store,
      optionFields: {
        FAMSTD: "FAMSTAND",
        PADRTYP: "ADRTYP",
        ANLIEGEN: "WEB_KONT_GRUND",
        GESCHLECHT: "GESCHLECHT",
        BEITRAGSPFLICHTIG: "KK_STATUS",
        PENSART: "PENSARTCUST",
        KRANKENKASSE: "KRANKENKASSE",
        PLKZ: "NATIONALITAET",
        KILO: "KILO",
      },
      dateFields: ["GEBDAT", "EHEDAT", "RENTENBEGINN"],
    };
  },
  beforeCreate: function () {
    if (typeof store.data.sService !== "object") store.data.sService = {};
    //this.$servertalk.doLog("Create");
    //this.$servertalk.doLog("Inhalt store:");
    //this.$servertalk.doLog(store);
  },
  methods: {
    getServices(store) {
      if (typeof store.selfService !== "object") store.selfService = {};
      if (typeof store.data.sService !== "object") store.data.sService = {};
      let filtered = JSON.stringify(store.selfService); // Objekt kopieren
      filtered = JSON.parse(filtered);
      const keys = Object.keys(filtered);
      keys.forEach((key, index) => {
        if (
          typeof store.data.sService[key] !== "object" ||
          Object.keys(store.data.sService[key]).length == 0
        )
          delete filtered[key];
      });
      return filtered;
    },
    hasEntries(arr) {
      if (!arr || typeof arr !== "object" || Object.keys(arr).length == 0)
        return false;
      return true;
    },
    removeData(idx, sName) {
      let messageCode = this.$servertalk.removeDataSelfService(sName, idx);
      store.message =
        messageCode == 0
          ? { text: "Ihr Änderungsauftrag wurde gelöscht.", status: "ok" }
          : {
            text:
              "Beim Löschen des Änderungsauftrags trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      /**
       * ein Änderungsauftrag wurde gelöscht
       */
      this.$emit("removed");
    },
  },
  emits: ["removed"],
  computed: {},
};
</script>
<template>
  <div v-for="(service, serviceIdx) in getServices(store)" :key="serviceIdx" class="offene-auftraege my-8">
    <h2 class="text-lg text-green-500 p-4 bg-green-100 rounded-md mb-2">
      Folgende Aufträge im Bereich
      <span class="font-bold">{{ service.title }}</span>
      wurden beauftragt aber noch nicht von Pensus abgerufen:
    </h2>
    <div v-for="(entry, entryIdx) in store.data.sService[serviceIdx]" :key="entryIdx"
      class="lg:flex gap-8 antrag-single flex-wrap">
      <div class="changed" v-for="(field, fieldIdx) in store.data.sService[serviceIdx][entryIdx][
    'changes'
  ]" :key="fieldIdx">
        <div v-if="typeof this.optionFields[fieldIdx] === 'string'" class="option-changed">
          <!-- Option field -->
          <span class="label">
            {{
    typeof store.selfServiceFields[fieldIdx] == "object"
      ? store.selfServiceFields[fieldIdx]["titleShort"]
      : fieldIdx
  }}:
          </span>
          <span class="val">
            {{ this.$servertalk.getName(this.optionFields[fieldIdx], field) }}
          </span>
        </div>
        <div v-else-if="this.dateFields.includes(fieldIdx)" class="date-changed">
          <!-- Date field -->
          <span class="label">
            {{
    typeof store.selfServiceFields[fieldIdx] == "object"
      ? store.selfServiceFields[fieldIdx]["titleShort"]
      : fieldIdx
  }}:
          </span>
          <input class="val font-normal disabled:bg-transparent" type="date" :value="field" disabled />
        </div>
        <div v-else-if="fieldIdx == 'KINDER' &&
    Object.keys(
      store.data.sService[serviceIdx][entryIdx]['changes']['KINDER']
    ).length > 0
    " class="children-changed">
          <!-- Kinder field-->
          <div v-for="(children, childrenIdx) in store.data.sService[serviceIdx][
    entryIdx
  ]['changes']['KINDER']" :key="childrenIdx">
            <div class="child flex gap-4">
              <div class="empty:hidden" v-for="(child, childIdx) in store.data.sService[serviceIdx][
    entryIdx
  ]['changes']['KINDER'][childrenIdx]" :key="childIdx">
                <div v-if="this.dateFields.includes(childIdx)" class="date-changed">
                  <!-- Date field -->
                  <span class="label">
                    {{
    typeof store.selfServiceFields[childIdx] == "object"
      ? store.selfServiceFields[childIdx]["titleShort"]
      : childIdx
  }}:
                  </span>
                  <input class="val font-normal disabled:bg-transparent" type="date" :value="child" disabled />
                </div>
                <div v-else-if="childIdx.substring(0, 7) !== 'uploads' &&
    childIdx !== 'uuid'
    ">
                  <span class="label">
                    {{
    typeof store.selfServiceFields[childIdx] == "object"
      ? store.selfServiceFields[childIdx]["titleShort"]
      : childIdx
  }}:
                  </span>
                  <span class="val">{{ child }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="fieldIdx !== 'KINDER' &&
    fieldIdx.substring(0, 7) !== 'uploads' &&
    fieldIdx !== 'uuid'
    " class="field-changed">
          <!-- Normal field -->
          <span class="label">
            {{
    typeof store.selfServiceFields[fieldIdx] == "object"
      ? store.selfServiceFields[fieldIdx]["titleShort"]
      : fieldIdx
  }}:
          </span>
          {{ field }}
        </div>

      </div>
      <div v-if="typeof store.data.sService[serviceIdx][entryIdx]['DokBetrRent'] == 'object' && store.data.sService[serviceIdx][entryIdx]['DokBetrRent'].length > 0">
        <span class="label">Erforderliche Unterlagen:</span>
        <pre>{{ store.data.sService[serviceIdx][entryIdx]['DokBetrRent'] }}</pre>
      </div>
      <a href="#" class="antrag-link ml-auto" @click.prevent="removeData(entryIdx, serviceIdx)">
        Änderungsauftrag löschen
      </a>
    </div>
  </div>
</template>
<style scoped>
.antrag-single {
  @apply even:bg-gray-50 lg:py-2 px-4 py-8 even:border-b border-gray-200 last:border-0 lg:flex text-sm lg:text-xs gap-y-4 gap-x-8 items-stretch;
}

.antrag-link {
  @apply text-primary inline-flex lg:flex items-center relative after:absolute after:transition-all after:duration-500 after:bg-primary after:w-0 hover:after:w-full after:top-full after:h-0.5;
}

.label {
  @apply font-bold block;
}
</style>
