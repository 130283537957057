<script>

/** Anzeige von Button "Eine Seite zurück"
 *   @displayName Back-Button
*/

export default {
    name: "ButtonBack",
    components: {},
    data() {
        return {};
    },
};
</script>
<template>
    <button @click="$router.back()"
        class="uppercase font-bold text-tertiary mt-16 flex px-5 py-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-500 rounded-md w-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6 mr-2 inline">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
        Eine Seite zurück
    </button>
</template>
