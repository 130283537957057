<template>
  <div class="relative select-none">
    <BaseProgressBar
      :value="score"
      :max="descriptions.length"
      :color="description.color"
      :text="description.text"
    />

    <p class="absolute mt-1 text-sm" :class="[description.text]">
      {{ description.label }}
    </p>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-de";
import BaseProgressBar from "../components/BaseProgressBar.vue";
/** Die Komponente ist verantwortlich für die Prüfung des neuen Passwortes
 * - das das alte Passwort muss eingegeben und überprüft werden
 * - ein neues, gutes Passwort muss erstellt werden
 * - das neue Passwort muss erneut, richtig eingegeben werden
 * die Kontrolle, ob das Passwort gut ist, wird an PasswortTestBar delegiert
 * @displayName Passwort Sicherheitsprüfung
 */
export default {
  components: {
    BaseProgressBar,
  },

  props: {
    /**
     * Das zu prüfende Passwort
     */
    value: {
      type: String,
      required: true,
    },
    /**
     * Ergebnis eines weiteren Tests von Aussen (Levenshtein oder altes PWD)
     */
    extok: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["status", "changed"],
  data() {
    return {
      serverok: false,
    };
  },

  setup(props, { emit }) {
    const descriptions = computed(() => [
      {
        color: "bg-red-600",
        label: "Das Passwort darf nicht mit Leerzeichen beginnen oder enden.",
        text: "[&+p]:text-red-600",
      },
      {
        color: "bg-red-600",
        label:
          "Dies Passwort entspricht Ihrer E-Mail, Ihrem Benutzernamen oder einem alten Passwort.",
        text: "[&+p]:text-red-600",
      },
      {
        color: "bg-red-600",
        label: "Leider ist dieses Passwort nicht sicher.",
        text: "[&+p]:text-red-600",
      },
      {
        color: "bg-red-400",
        label: "Leider ist dieses Passwort nicht sicher genug.",
        text: "[&+p]:text-red-400",
      },
      {
        color: "bg-yellow-600",
        label: "Ihr Passwort ist noch nicht ganz sicher.",
        text: "[&+p]:text-yellow-600",
      },
      {
        color: "bg-green-400",
        label: "Ihr Passwort ist fast sicher.",
        text: "[&+p]:text-green-400",
      },
      {
        color: "bg-green-600",
        label: "Sie haben ein sicheres Passwort eingegeben.",
        text: "[&+p]:text-green-600",
      },
    ]);

    const description = computed(() =>
      props.value && props.value.length > 0
        ? descriptions.value[score.value - 1]
        : {
            color: "bg-transparent",
            label: "Wir prüfen Ihr eingegebenes Passwort auf Sicherheit.",
            text: "",
          }
    );

    const pass = computed(() => props.value);

    const score = computed(() => {
      const hasValue = props.value && props.value.length > 0;
      const eOK = props.extok;
      const eLeerzeichen = props.value.trim() != props.value;

      if (!hasValue) {
        return 0;
      }
      if (eLeerzeichen) {
        return 1;
      }

      //this.$servertalk.checkpass(props.value,(status) => {this.$servertalk.doLog(status)} );
      //this.$servertalk.checkpass(props.value);

      //if (!this.serverok){return 1 }
      //this.$servertalk.doLog('extOK: '+eOK);

      return !eOK ? 2 : zxcvbn(props.value).score + 3;
      //return zxcvbn(props.value).score + 1;
    });

    const isPasswordStrong = computed(() => score.value >= 6);
    watch(isPasswordStrong, (value) => {
      /**
       * score des PasswortTestBar, bei Emit des Testbars
       * @property {boolean} value Kennung, ob Passwort stark ist
       */
      emit("status", value);
    });
    const password = "somePassword";
    watch(pass, (value) => {
      /**
       score des PasswortTestBar, bei Emit des Testbars
       * @property {number} value Passwortstärke
       */
      emit("changed", score.value);
    });

    const options = {
      translations: zxcvbnEnPackage.translations,
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
    };

    zxcvbnOptions.setOptions(options);

    zxcvbn(password);

    return {
      descriptions,
      description,
      score,
    };
  },
};
</script>
