<template>
  <div class="container">
    <div>
      <input
        maxlength="255"
        class="block w-full text-sm text-secondary file:mr-4 file:px-4 file:py-3 bg-gray-100 file:border-0 file:text-sm file:font-semibold file:bg-primary file:text-white hover:file:bg-tertiary cursor-pointer file:cursor-pointer border border-gray-200 rounded-md"
        id="file_input"
        type="file"
        @change="handleFileUpload($event)" />
      <br />
      <button
        v-on:click="submitFile()"
        :disabled="store.isReadOnly"
        class="bg-primary text-white py-2 px-6 rounded-md text-xl block hover:bg-tertiary transition mt-4 mx-auto">
        hochladen
      </button>
    </div>
  </div>
</template>

<script>
import { store } from "./store.vue";

export default {
  data() {
    return {
      //file: "",
      files: store.uploadFiles.files,
    };
  },

  methods: {
    handleFileUpload(event) {
      //this.file = event.target.files[0];
      this.files.push(...event.target.files);
    },

    submitFile() {
      //let formData = new FormData();
      //formData.append("file", this.files);
      /*axios
        .post("http://pensus.dv.int/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          //console.log("SUCCESS!!");
        })
        .catch(function () {
          //console.log("FAILURE!!");
        });
    },*/
    },
  },
};
</script>
