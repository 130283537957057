<script>
import { store } from "../components/store.vue";
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import SchnellZugriff from "../components/SchnellZugriff.vue";
import AenderungsauftraegeSelfService from "../components/AenderungsauftraegeSelfService.vue";
import Alertbox from "../components/Alertbox.vue";
import DocumentCaptureVue from "../components/DocumentCapture.vue";
/** View-Komponente zur Anzeige von Schnellzugriff, ungespeicherten Änderungen sowie nicht abgeholter Änderungsaufträge
 * @displayName Seite Schnellzugriff
 */
export default {
  name: "HomePage",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    SchnellZugriff,
    AenderungsauftraegeSelfService,
    Alertbox,
    DocumentCaptureVue,
  },
  data() {
    return {
      inBearbeitung: {
        AenderungPersoenlichedaten: false,
        AenderungBankverbindung: false,
        ErfassungSteuerId: false,
        AenderungKontaktdaten: false,
        AntragBetriebsrente: false,
        AenderungKrankenkasse: false,
        KontaktPensus: false,
      },
      unversandt: {
        AenderungPersoenlichedaten: false,
        AenderungBankverbindung: false,
        ErfassungSteuerId: false,
        AenderungKontaktdaten: false,
        AntragBetriebsrente: false,
        AenderungKrankenkasse: false,
        KontaktPensus: false,
      },
      store,
    };
  },
  mounted() {
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    window.scrollTo(0, 0);
    this.chckUnbearbeitet();
  },
  methods: {
    getServices(store) {
      //let filtered = JSON.stringify(store.selfService); // Objekt kopieren
      let filtered = {};
      //filtered = JSON.parse(filtered);
      const keys = Object.keys(store.selfService);
      keys.forEach((key) => {
        let e = store.selfService[key];
        if (
          // nötige Ausschlusskriterien für Services
          (this.getInBearbeitung(key) || this.getUnversandt(key)) && // Anzeige nur wenn in Änderung 
          e.image.length > 0 && //es muss ein Bild da sein
          (e.conditionHide.indexOf("goLive") < 0 || !store.goLive) && //golive darf nicht gefordert sein ODER wir sind NICHT goLive
          (e.conditionHide.indexOf("notAnwaerter") < 0 ||
            this.$servertalk.checkAnwaerter()) && // Nicht nur für Anwäerter oder er ist Anwärter
          (e.conditionHide.indexOf("notVerbotFirma") < 0 ||
            this.$servertalk.checkVerbotFirma()) &&   //nur erlaubte Firmen  
          (e.conditionHide.indexOf("notRentner") < 0 ||
            this.$servertalk.checkRentner()) && // Nur für Rentner
          (e.conditionHide.indexOf("Steuernummer") < 0 ||
            store.data.Stammdaten.STEUERIDNR.length <= 0) //keine Steuernummer-Verpflichtung oder er hat keine
        ) {
          filtered[key] = e;
          this.$servertalk.doLog(e.conditionHide.indexOf("notAnwaerter") >= 0);
          this.$servertalk.doLog(key);
        }
      });

      this.$servertalk.doLog(filtered);
      /*
      const keys = Object.keys(filtered);
      this.$servertalk.doLog(keys);
      keys.forEach((key, index) => {
        if (
          filtered[key].image.length == 0 || // Ausschlusskriterien für Services
          (filtered[key].conditionHide.indexOf("goLive") >= 0 &&
            store.goLive) ||
          (filtered[key].conditionHide.indexOf("notAnwaerter") >= 0 &&
            this.$servertalk.checkAnwaerter()) ||
          (filtered[key].conditionHide.indexOf("Steuernummer") >= 0 &&
            store.data.Stammdaten.STEUERIDNR.length > 0)
        )
          delete filtered[key];
      });
      */
      return filtered;
    },
    getInBearbeitung(index) {
      return this.inBearbeitung[index];
    },
    getUnversandt(index) {
      return this.unversandt[index];
    },
    chckUnbearbeitet() {
      this.$servertalk.doLog(store);
      for (const [key] of Object.entries(this.inBearbeitung)) {
        this.inBearbeitung[key] = this.$servertalk.gettAttr(
          "draft.sService.Entwuerfe." + key
        );
        this.unversandt[key] = this.$servertalk.checkForUnsentSelfService(key);
      }
    },
  },
  beforeCreate: function () {
    if (!store.auth) {
      this.$router.push("/");
    }
  },
  computed: {
    ungespeicherteEingaben() {
      // Prüfen, ob es in mind. einem Bereich ungespeicherte Änderungen gibt
      for (const [key] of Object.entries(this.inBearbeitung)) {
        if (this.inBearbeitung[key] || this.unversandt[key]) return true;
      }
      return false;
    },
    isWartung() {
      let wartung = false;
      let omessage = store.config?.messages?.DBWartung ?? false;
      let now = new Date();
      if (omessage && Date.parse(omessage.von) < now && Date.parse(omessage.bis) > now) {
        wartung = true;
      }
      return wartung;
    },
    messageWartung() {
      let message = '';
      if (this.isWartung) {
        message = store.config?.messages?.DBWartung?.message ?? '';
      }

      return message;
    }

  },
};
</script>
<template>
  <div class="main-wrapper flex-grow" :class="store.accessRestriction">
    <HauptMenue />
    <div class="hero-image-wrapper">
      <img
        class="max-h-[200px] md:max-h-[400px] lg:max-h-[600px] object-top overflow-hidden object-cover w-full mt-[100px] lg:mt-0"
        src="/assets/img/pensus-aelteres-paerchen-vor-laptop.jpg" role="img"
        />
      <div class="hero-description-block max-w-7xl mx-auto relative">
        <div
          class="hero-inside bg-primary px-5 py-5 lg:py-10 lg:p-12 relative lg:absolute text-white max-w-full lg:max-w-lg lg:bottom-12">
          <h1 class="text-xl lg:text-4xl text-left font-bold text-white tracking-wider mb-2">
            Willkommen im Pensus Serviceportal
            <br />
            <span class="font-normal text-lg lg:text-2xl">
              {{ store.data.Stammdaten.TITEL }}
              {{ store.data.Stammdaten.VORNAME }}
              {{ store.data.Stammdaten.NAMEZUS }}
              {{ store.data.Stammdaten.NAMEVS }}
              {{ store.data.Stammdaten.NAME }}
            </span>
          </h1> 
          <h2>
            Ihrem digitalen Verwaltungsbereich rund um die betriebliche
            Altersversorgung
          </h2>
          <Alertbox />
        </div>
      </div>
    </div>
    <SchnellZugriff />
    <div 
      v-if="ungespeicherteEingaben" class="auftraege-section pt-8 pb-8 mt-8 mb-16 px-5 xl:px-0 max-w-7xl mx-auto">
      <h2 class="text-3xl lg:text-4xl font-bold text-primary mb-8 lg:mb-12 text-center">
        Meine Aufträge
      </h2>
      <div v-if="isWartung"
        class="p-5 text-primary border border-primary bg-primary/10 rounded-md my-5 max-w-3xl mx-auto">{{
    messageWartung }}</div>
      <div class="alertbox" :class="{ group: store.message.text }">
        <div
          class="fixed translate-y-[999px] group-[.alertbox]:translate-y-0 bottom-8 transition-all duration-1000 left-1/2 -translate-x-1/2 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white z-[70]">
          <div class="mt-3 text-center">
            <div v-if="store.message.status === 'ok' || store.message.status === ''
    " class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg class="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <div v-else class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg class="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>

            <div class="mt-2 px-7 py-3">
              <p class="text-sm font-bold text-tertiary whitespace-pre-line">
                {{ store.message.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="unversandt">
        <AenderungsauftraegeSelfService @removed="chckUnbearbeitet" />
      </div>
      <div
        class="grid gap-5 md:grid-cols-2 xl:grid-cols-3 [&>.item>a>.item-text]:uppercase [&>.item>a>.item-text]:font-bold [&>.item>a>.item-text]:text-center [&>.item>a>.item-text]:px-2 [&>.item>a>.item-text]:text-white [&>.item>a>.item-text]:transition-all [&>.item>a>.item-text]:duration-500 [&>.item>a>.item-text]:py-2 [&>.item>a>.item-image]:border-t [&>.item>a>.item-image]:border-primary">
        <div v-for="(service, index) in getServices(store)" :key="index" class="item group">
          <router-link active-class="font-bold" :to="service.url" class="block">
            <div class="item-image relative">
              <img :src="service.image" />
              <div v-if="getInBearbeitung(index)" class="status-label absolute top-0 right-0">
                <span class="bearbeitung bg-yellow-500 text-tertiary block py-1 px-2 text-sm uppercase">
                  Entwurf
                </span>
              </div>
              <div v-if="getUnversandt(index)" class="status-label absolute top-0 left-0">
                <span class="unversandt bg-green-500 text-white block py-1 px-2 text-sm uppercase">
                  In Auftrag
                </span>
              </div>
            </div>
            <div class="item-text bg-tertiary/80 group-hover:bg-tertiary">
              {{ service.title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <FooterMenu />
  </div>
</template>
