<script>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
//import PasswortAendernComponent from "../components/PasswortAendernComponent.vue";
import PasswordInputForm from "../components/PasswordInputForm";
import Alertbox from "../components/Alertbox.vue";
import { store } from "../components/store.vue";

/*
verantwortlich für die Änderung der Zugangsdaten
- wenn Zugansdaten geändert werden MÜSSEN, dann wird dies dargestellt
- wenn Zugansdaten geändert werden MÜSSEN, dann wird dies durchgesetzt
- 

die Kontrolle, ob das Passwort gut ist, wird an PasswortTestBar delegiert

in: nix
out: emits 

emits: 
status: score des PasswortTestBar, bei Emit des Testbars
changed: score des newPassword1 bei jeder Änderung des neuen (1.) Passwortes
newpasswd: das neue Passwort, bei jeder Änderung
passwdOK: wenn das alte Passwort passt, das neue Passwort genug score hat und zum 2. mal korrekt eingegeben wurde, bei Änderungen


*/

export default {
  name: "ZugangsdatenAendern",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    PasswordInputForm,
    Alertbox,
    // PasswortAendernComponent
  },
  data() {
    return {
      EMAIL: store.data.Stammdaten.EMAIL,
      store,
      meldung: "",
      newPasswd: "",
      passStatus: false,
      dataEntered: false,
      isEmailAccordionOpen:
        store.data.Stammdaten.IS_USER_INIT == "1" ||
        this.$route.params.open === "EMail" ||
        this.$route.params.open === "both",
      isPassAccordionOpen:
        store.data.Stammdaten.IS_PWD_INIT == "1" ||
        this.$route.params.open === "passwd" ||
        this.$route.params.open === "both",
    };
  },
  watch: {
    EMAIL: {
      handler(newData, oldData) {
        this.dataEntered = true;
      },
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    const query = this.$route.params.open;
    /*if (query.openEmailAccordion === "true") {
      this.isEmailAccordionOpen = true;
    }

    if (query.openPasswordAccordion === "true") {
      this.isPassAccordionOpen = true;
    }*/
  },
  methods: {
    setNewPasswd(v) {
      this.newPasswd = v;
      this.$servertalk.doLog("##newPasswd");
      this.$servertalk.doLog(this.newPasswd);
      this.dataEntered = true;
    },
    passOk(v) {
      this.passStatus = v;
      this.$servertalk.doLog("##Passok");
      this.$servertalk.doLog(this.passStatus);
    },

    setMeldung(msg) {
      this.meldung = msg;
    },
    toggleEmailAccordion() {
      this.isEmailAccordionOpen = !this.isEmailAccordionOpen;
      if (this.isEmailAccordionOpen) {
        this.isPassAccordionOpen = false; // Schließe das Passwort-Akkordeon
      }
    },

    togglePassAccordion() {
      this.isPassAccordionOpen = !this.isPassAccordionOpen;
      if (this.isPassAccordionOpen) {
        this.isEmailAccordionOpen = false; // Schließe das E-Mail-Akkordeon
      }
    },
    sendData(e) {
      // Click-Function auf Button
      if (e) e.preventDefault();

      //this.setMeldung("starte");
      !this.passStatus || !this.isEmailok;
      if (
        this.isEmailok &&
        this.store.data.Stammdaten.EMAIL != this.EMAIL.toLowerCase()
      ) {
        this.store.data.Stammdaten.EMAIL = this.EMAIL.toLowerCase();
        this.$servertalk.doLog(
          "neue E-Mail " + this.store.data.Stammdaten.EMAIL
        );
        this.store.data.Stammdaten.IS_USER_INIT = "0";
      }
      if (this.passStatus && this.newPasswd.length > 5) {
        this.store.newpasswd = this.newPasswd;
        this.$servertalk.doLog("neues Passwd " + this.store.newpasswd);
        this.store.data.passwdModified = "true";
        this.store.data.passUUID = this.$servertalk.getUUID();
        this.store.data.Stammdaten.IS_PWD_INIT = "0";
      }

      //store.data.Stammdaten.IS_USER_INIT = 0;
      this.$servertalk.doLog("Änderung bereit zum senden");
      //this.$servertalk.sendDataToDB();

      this.$router.push("/startseite");

      //senden passiert automatisch
      //this.$servertalk.sendDataToDB(this.$event, this.setStatus);
    },
  },

  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
  computed: {
    setStatusClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      const PrivatadresseValue =
        this.$servertalk.gettAttr("data.Privatadresse");

      // Benutze den Wert als Klasse
      return PrivatadresseValue;
    },
    setStatusClassEMAIL() {
      // Erhalte den Wert "Privatadresse.EMAIL von $servertalk
      return this.$servertalk.gettAttr("data.Privatadresse.EMAIL");
    },
    isEmailok() {
      let lMail = this.EMAIL.toLowerCase();
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
      const berlinhypRegex = /.*@berlinhyp\.de$/i;
      const sServicepartner = /.*@s-servicepartner\.de$/i;

      if (!emailRegex.test(lMail)) {
        this.emailErrorMessage = "Ungültige E-Mail Adresse.";
      } else if (berlinhypRegex.test(lMail)) {
        this.emailErrorMessage =
          "Die Domain '@berlinhyp.de' ist nicht erlaubt.";
      } else if (sServicepartner.test(lMail)) {
        this.emailErrorMessage =
          "Die Domain '@s-servicepartner.de' ist nicht erlaubt.";
      } else if (lMail === store.data.Stammdaten.EMAIL && this.isEmailInit) {
        this.emailErrorMessage =
          "Sie müssen eine neue, echte E-Mail Adresse eingeben.";
      } else {
        this.emailErrorMessage = "";
      }

      return !this.emailErrorMessage;
    },
    isEmailInit() {
      return this.$servertalk.gettValue("data.Stammdaten.IS_USER_INIT") == "1";
    },
    isPasswdInit() {
      return this.$servertalk.gettValue("data.Stammdaten.IS_PWD_INIT") == "1";
    },
  },
};
let uuid = 0;
</script>
<template>
  <div
    class="main-wrapper flex-grow"
    :class="store.accessRestriction"
    v-if="store.data.Stammdaten.EMAIL"
  >
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 mb-16 max-w-2xl mx-auto px-5 lg:px-0">
      <h1
        class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase"
      >
        Zugangsdaten Ändern
      </h1>
      <Alertbox />
      <button
        @click="toggleEmailAccordion()"
        class="font-semibold lg:text-xl trigger py-4 pl-4 pr-8 sm:px-4 bg-gray-100 rounded-md cursor-pointer text-primary relative hover:bg-gray-200 text-left sm:text-center transition flex hyphens-manual items-center justify-between space-x-3 w-full mb-2 duration-500"
        :aria-expanded="isEmailAccordionOpen"
        :aria-controls="`collapse-0`"
        :class="{
          '!mb-0 rounded-b-none bg-gray-200': isEmailAccordionOpen,
          'pointer-events-none': isEmailInit,
        }"
      >
        E-Mail Adresse ändern

        <div
          class="toggle relative before:h-1 after:transition-all after:duration-500 before:w-6 before:-right-4 before:bg-primary before:absolute -top-0.5 sm:before:right-0 after:h-1 after:w-6 after:bg-primary after:absolute after:-right-4 sm:after:right-0 after:-rotate-90 before:rounded after:rounded"
          :class="{ hidden: isEmailInit }"
        ></div>
      </button>
      <div
        :id="`collapse-0`"
        class="max-h-0 opacity-0 transition-all duration-500 overflow-hidden bg-gray-50"
        :class="{
          'max-h-[2000px] !opacity-100 rounded-b-md mb-2': isEmailAccordionOpen,
        }"
      >
        <form :class="setStatusClass">
          <div class="relative mt-5 mx-5">
            <label for="email" class="text-xl block">
              Meine E-Mail Adresse
            </label>
            <p class="text-sm mb-2">
              Geben Sie hier Ihre neue E-Mail Adresse ein.
            </p>
            <p class="text-sm mb-2 text-red-500" v-if="!isEmailok">
              {{ emailErrorMessage }}
            </p>
            <input
              maxlength="255"
              type="email"
              id="email"
              class="w-full p-3 border rounded-md invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 mb-8"
              :class="
                setStatusClassEMAIL + !isEmailok
                  ? 'border-red-500 bg-red-50 text-red-500 '
                  : ' text-tertiary border-secondary/50 '
              "
              required
              v-model="EMAIL"
              inputmode="email"
            />
          </div>
        </form>
      </div>

      <button
        @click="togglePassAccordion()"
        class="font-semibold lg:text-xl trigger py-4 pl-4 pr-8 sm:px-4 bg-gray-100 rounded-md cursor-pointer text-primary relative hover:bg-gray-200 text-left sm:text-center transition flex hyphens-manual items-center justify-between space-x-3 w-full mb-2 duration-500"
        :aria-expanded="isPassAccordionOpen"
        :aria-controls="`collapse-1`"
        :class="{
          '!mb-0 rounded-b-none bg-gray-200': isPassAccordionOpen,
        }"
      >
        Passwort ändern

        <div
          class="toggle relative before:h-1 after:transition-all after:duration-500 before:w-6 before:-right-4 before:bg-primary before:absolute -top-0.5 sm:before:right-0 after:h-1 after:w-6 after:bg-primary after:absolute after:-right-4 sm:after:right-0 after:-rotate-90 before:rounded after:rounded"
        ></div>
      </button>
      <div
        :id="`collapse-1`"
        class="max-h-0 opacity-0 transition-all duration-500 overflow-hidden bg-gray-50 py-5"
        :class="{
          'max-h-[2000px] !opacity-100 rounded-b-md mb-2': isPassAccordionOpen,
        }"
      >
        <PasswordInputForm
          @status="console.log('#+')"
          @changed="console.log('#-')"
          @newpasswd="setNewPasswd"
          @passwdOK="passOk"
          :forceNew="isPasswdInit"
          :newEmail="this.EMAIL.toLowerCase()"
        />
      </div>
      <button
        class="bg-primary text-white py-4 px-32 rounded-md text-xl mx-auto block hover:bg-tertiary transition mt-8"
        :class="[
          {
            'bg-secondary pointer-events-none':
              !passStatus || !isEmailok || !dataEntered,
          },
        ]"
        @click="sendData($event)"
        :disabled="!passStatus || !isEmailok || !dataEntered"
      >
        Ändern
      </button>
    </div>
  </div>
  <FooterMenu />
</template>
